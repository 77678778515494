import React from "react";
import {AdvancedSearch} from "../components/AdvancedSearch";
import {TabPanel, TabView} from "primereact/tabview";
import {Maps} from "../components/Maps";
import {GlowGlobe} from "../components/glowglobe/GlowGlobe";

export const Search = () => {

    return (

    <div>
        <div className="search-bar-layout-content search-bar">
                <span className="p-input-icon-right display-search">
                    <AdvancedSearch ></AdvancedSearch>
                </span>
        </div>
        <div className="tabview-demo our-layout-content">

            <div className="card">
                <TabView className="tableview-tab">
                    <TabPanel leftIcon="fad fa-map-marked-alt" header="Maps">
                        <GlowGlobe />
                    </TabPanel>
                </TabView>
            </div>

        </div>
    </div>



    );
}

