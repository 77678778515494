import React, {useEffect, useRef, useState } from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


export const HTMLLegend = (props) => {

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    useEffect(() => {
        if(props.data.legend.type === "categorical")
        {
            setCategories(props.data.legend.data);
        }
    }, [props.data.id]);

    const onRowReorder = (e) => {
        setCategories(e.value);
    }


    const colorTemplate = (data,props) =>{
        //{data[props.field]}
        return (
            <div style={{width:"50px",height:"25px",backgroundColor:data[props.field]}}>
            </div>
        )

    }

    const labelTemplate = (data,props) =>{
        return (
            <div>
                {data[props.field]}
            </div>
        )

    }

    return (
        <div className={props.className}>
            <DataTable
                value={categories}
                dataKey="id"
                className="p-datatable-customers p-datatable-sm"
                autoLayout={true}
                stripedRows
                emptyMessage = "No Available Data"
                selection={selectedCategories}
                onSelectionChange={e => setSelectedCategories(e.value)}
                onRowReorder={onRowReorder}
                selectionMode="checkbox"
            >

                <Column rowReorder rowReorderIcon="fad fa-arrows" style={{width: '2em'}} />
                <Column
                    key="color"
                    field="color"
                    header="Color"
                    body={(data,props) => colorTemplate(data,props)}
                />
                <Column
                    key="label"
                    field="label"
                    header="Label"
                    body={(data,props) => labelTemplate(data,props)}
                />
                <Column selectionMode="multiple" style={{width:'2em'}}/>


            </DataTable>
        </div>

    );

}
