import React, {useEffect, useState} from "react";
import {Fieldset} from "primereact/fieldset";
import QueryBuilder, {defaultValidator, defaultValueProcessor} from "react-querybuilder";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/components/button/Button";
import { formatQuery } from 'react-querybuilder';
import {useHistory} from "react-router-dom";
import {SearchGuide} from "./SearchGuide";

export const AdvancedSearch = (props) => {
    const [queryPath, setQueryPath] = useState(null);
    const [userQuery, setUserQuery] = useState("");
    const [panelCollapsed, setPanelCollapsed] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [enableSearch, setEnableSearch] = useState(null);
    //-------------------
    const [enableAdvancedSearch, setEnableAdvancedSearch] = useState(true);
    //-------------------

    let history = useHistory();


    const [query, setQuery] = useState({
        rules: [
            {
                field: 'allFields',
                value: '',
                operator: 'contains'
            }
        ],
            combinator: 'and',
            not: false
    });

    const valueProcessor = (field, operator, value) => {

        if (!value) {
            value = 'wrong_qbuilder_value';
        }
        else {
            if ( (field ==="release_year") || (field==="temporal_coverage") ) {
                let current_year=new Date().getFullYear();
                if ( (value < 1971) || (value > current_year)) {
                    value = 'wrong_qbuilder_value';
                }
            }
            else {
                let trimStr = value.replace(/\s+/g, '');
                if (trimStr==="") {
                    value = 'wrong_qbuilder_value';
                }
            }
        }
        //----------------------
        return defaultValueProcessor(field, operator, value);
    };


    const logQuery = (query) => {

        let tempstr = (userQuery).replace(/\"/g, "\\\"");
        while (tempstr.includes("\\\\")) { tempstr = tempstr.replace(/\\\\/g, "\\"); }
        //-------------------------------
        let tempQP = "/search-results/0/" +
            "{\"user_query\":\"" + tempstr +
            "\",\"json_query\":" + formatQuery(query, 'json_without_ids').replace(/\r/g,'')+"}"

        //-------------------------------
        setQueryPath(tempQP);
        //-------------------------------
        if(!panelCollapsed){
            let XsearchQuery = formatQuery(query, { format: 'sql', valueProcessor });
            //---------------------------
            setSearchQuery("< Advanced Search >");
            //---------------------------
            let trimStr = XsearchQuery.replace(/\s+/g, '');
            // setSearchQuery(trimStr);
            //---------------------------
            if ( (trimStr.includes("wrong_qbuilder_value")) || (trimStr.includes("(1=1)")) || (trimStr.includes("%%")) ) {
                setEnableSearch(null);
            } else {
                setEnableSearch(true);
            }
        }
    }

    useEffect(() => {
        if(props.supportADVANCED){

            if (props.supportADVANCED === "false") {
                setEnableAdvancedSearch(false);
            }

        }

    }, [props.supportADVANCED]);

    useEffect(() => {
        if(props.user_query){

            setQuery(props.user_query.json_query);

            setUserQuery(props.user_query.user_query);

            setPanelCollapsed(false);

            logQuery(query);

        }

    }, [props.user_query]);

    const testYear = (value) => {
        if ( (!value) || (value==="") ) {
            return false;
        }
        else {
            let current_year=new Date().getFullYear();
            if ( (value >= 1971) && (value <= current_year)) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    const testEmpty = (value) => {

        if (!value) {
            return false;
        }
        else {
            let trimStr = value.replace(/\s+/g, '');

            if (trimStr === "") {
                return false;
            }
            else {
                return true;
            }
        }
    }

    const fields = [
        {name: 'allFields', label: 'Title / Description / Keywords', operators: [{ name: 'contains', label: 'contains' },{ name: 'doesNotContain', label: 'does not contain' }], validator: ({ value }) => testEmpty(value)},
        {name: 'title', label: 'Title', operators: [{ name: 'contains', label: 'contains' },{ name: 'doesNotContain', label: 'does not contain' }], validator: ({ value }) => testEmpty(value)},
        {name: 'description', label: 'Description', operators: [{ name: 'contains', label: 'contains' },{ name: 'doesNotContain', label: 'does not contain' }], validator: ({ value }) => testEmpty(value)},
        {name: 'controlledTerms', label: 'Controlled Term', operators: [{ name: '=', label: 'is' },{ name: '!=', label: 'is not' }], validator: ({ value }) => testEmpty(value)},
        {name: 'otherKeywords', label: 'Other Keyword', operators: [{ name: '=', label: 'is' },{ name: '!=', label: 'is not' }], validator: ({ value }) => testEmpty(value)},
        {name: 'region', label: 'Region', operators: [{ name: '=', label: 'is' },{ name: '!=', label: 'is not' }],
            valueEditorType: 'select', values:[
                {name: "UNM49:001", label: "World"},
                {name: "UNM49:002", label: "Africa"},
                {name: "UNM49:015", label: "Africa / Northern Africa"},
                {name: "UNM49:202", label: "Africa / Sub-Saharan Africa"},
                {name: "UNM49:014", label: "Africa / Sub-Saharan Africa / Eastern Africa"},
                {name: "UNM49:017", label: "Africa / Sub-Saharan Africa / Middle Africa"},
                {name: "UNM49:018", label: "Africa / Sub-Saharan Africa / Southern Africa"},
                {name: "UNM49:011", label: "Africa / Sub-Saharan Africa / Western Africa"},
                {name: "UNM49:019", label: "Americas"},
                {name: "UNM49:419", label: "Americas / Latin America and the Caribbean"},
                {name: "UNM49:029", label: "Americas / Latin America and the Caribbean / Caribbean"},
                {name: "UNM49:013", label: "Americas / Latin America and the Caribbean / Central America"},
                {name: "UNM49:005", label: "Americas / Latin America and the Caribbean / South America"},
                {name: "UNM49:021", label: "Americas / Northern America"},
                {name: "UNM49:010", label: "Antarctica"},
                {name: "UNM49:142", label: "Asia"},
                {name: "UNM49:143", label: "Asia / Central Asia"},
                {name: "UNM49:030", label: "Asia / Eastern Asia"},
                {name: "UNM49:035", label: "Asia / South-eastern Asia"},
                {name: "UNM49:034", label: "Asia / Southern Asia"},
                {name: "UNM49:145", label: "Asia / Western Asia"},
                {name: "UNM49:150", label: "Europe"},
                {name: "UNM49:151", label: "Europe / Eastern Europe"},
                {name: "UNM49:154", label: "Europe / Northern Europe"},
                {name: "UNM49:830", label: "Europe / Northern Europe / Channel Islands"},
                {name: "UNM49:039", label: "Europe / Southern Europe"},
                {name: "UNM49:155", label: "Europe / Western Europe"},
                {name: "UNM49:009", label: "Oceania"},
                {name: "UNM49:053", label: "Oceania / Australia and New Zealand"},
                {name: "UNM49:054", label: "Oceania / Melanesia"},
                {name: "UNM49:057", label: "Oceania / Micronesia"},
                {name: "UNM49:061", label: "Oceania / Polynesia"}
                ]
        },

        {name: 'oceans', label: 'Oceans & Seas', operators: [{ name: '=', label: 'is' },{ name: '!=', label: 'is not' }],
            valueEditorType: 'select', values:[
                {name: "MRGid:1901", label: "World Ocean"},
                {name: "MRGid:1906", label: "World Ocean / Arctic Ocean"},
                {name: "MRGid:1902", label: "World Ocean / Atlantic Ocean"},
                {name: "MRGid:1912", label: "World Ocean / Atlantic Ocean / North Atlantic Ocean"},
                {name: "MRGid:1914", label: "World Ocean / Atlantic Ocean / South Atlantic Ocean"},
                {name: "MRGid:1904", label: "World Ocean / Indian Ocean"},
                {name: "MRGid:4266", label: "World Ocean / Indian Ocean / Arabian Gulf"},
                {name: "MRGid:4268", label: "World Ocean / Indian Ocean / Arabian Sea"},
                {name: "MRGid:4265", label: "World Ocean / Indian Ocean / Gulf of Aden"},
                {name: "MRGid:1903", label: "World Ocean / Pacific Ocean"},
                {name: "MRGid:18101", label: "World Ocean / Pacific Ocean / Central Pacific Ocean"},
                {name: "MRGid:14288", label: "World Ocean / Pacific Ocean / East Pacific Ocean"},
                {name: "MRGid:18061", label: "World Ocean / Pacific Ocean / Eastern Central Pacific Ocean"},
                {name: "MRGid:14289", label: "World Ocean / Pacific Ocean / Indo-Pacific Ocean"},
                {name: "MRGid:17965", label: "World Ocean / Pacific Ocean / North East Pacific Ocean"},
                {name: "MRGid:1908", label: "World Ocean / Pacific Ocean / North Pacific Ocean"},
                {name: "MRGid:17856", label: "World Ocean / Pacific Ocean / South East Pacific Ocean"},
                {name: "MRGid:1910", label: "World Ocean / Pacific Ocean / South Pacific Ocean"},
                {name: "MRGid:18072", label: "World Ocean / Pacific Ocean / Tropical East Pacific Ocean"},
                {name: "MRGid:18071", label: "World Ocean / Pacific Ocean / Tropical Pacific Ocean"},
                {name: "MRGid:18073", label: "World Ocean / Pacific Ocean / Tropical West Pacific Ocean"},
                {name: "MRGid:14290", label: "World Ocean / Pacific Ocean / West Pacific Ocean"},
                {name: "MRGid:14852", label: "World Ocean / Pacific Ocean / Western Central Pacific Ocean"},
                {name: "MRGid:1907", label: "World Ocean / Southern Ocean"},
                {name: "MRGid:2401", label: "World Ocean / Baltic Sea"},
                {name: "MRGid:3319", label: "World Ocean / Black Sea"},
                {name: "MRGid:1905", label: "World Ocean / Mediterranean Sea"},
                {name: "MRGid:4264", label: "World Ocean / Red Sea"},
                {name: "MRGid:4331", label: "World Ocean / South China and Eastern Archipelagic Seas"}
            ]
        },


        {name: 'country', label: 'Country', operators: [{ name: '=', label: 'is' },{ name: '!=', label: 'is not' }],
            valueEditorType: 'select', values:[
                {name: "UNM49:004", label: "Afghanistan"},
                {name: "UNM49:248", label: "Aland Islands"},
                {name: "UNM49:008", label: "Albania"},
                {name: "UNM49:012", label: "Algeria"},
                {name: "UNM49:016", label: "American Samoa"},
                {name: "UNM49:020", label: "Andorra"},
                {name: "UNM49:024", label: "Angola"},
                {name: "UNM49:660", label: "Anguilla"},
                {name: "UNM49:010", label: "Antarctica"},
                {name: "UNM49:028", label: "Antigua and Barbuda"},
                {name: "UNM49:032", label: "Argentina"},
                {name: "UNM49:051", label: "Armenia"},
                {name: "UNM49:533", label: "Aruba"},
                {name: "UNM49:036", label: "Australia"},
                {name: "UNM49:040", label: "Austria"},
                {name: "UNM49:031", label: "Azerbaijan"},
                {name: "UNM49:044", label: "Bahamas"},
                {name: "UNM49:048", label: "Bahrain"},
                {name: "UNM49:050", label: "Bangladesh"},
                {name: "UNM49:052", label: "Barbados"},
                {name: "UNM49:112", label: "Belarus"},
                {name: "UNM49:056", label: "Belgium"},
                {name: "UNM49:084", label: "Belize"},
                {name: "UNM49:204", label: "Benin"},
                {name: "UNM49:060", label: "Bermuda"},
                {name: "UNM49:064", label: "Bhutan"},
                {name: "UNM49:068", label: "Bolivia (Plurinational State of)"},
                {name: "UNM49:535", label: "Bonaire, Sint Eustatius and Saba"},
                {name: "UNM49:070", label: "Bosnia and Herzegovina"},
                {name: "UNM49:072", label: "Botswana"},
                {name: "UNM49:074", label: "Bouvet Island"},
                {name: "UNM49:076", label: "Brazil"},
                {name: "UNM49:086", label: "British Indian Ocean Territory"},
                {name: "UNM49:092", label: "British Virgin Islands"},
                {name: "UNM49:096", label: "Brunei Darussalam"},
                {name: "UNM49:100", label: "Bulgaria"},
                {name: "UNM49:854", label: "Burkina Faso"},
                {name: "UNM49:108", label: "Burundi"},
                {name: "UNM49:132", label: "Cabo Verde"},
                {name: "UNM49:116", label: "Cambodia"},
                {name: "UNM49:120", label: "Cameroon"},
                {name: "UNM49:124", label: "Canada"},
                {name: "UNM49:136", label: "Cayman Islands"},
                {name: "UNM49:140", label: "Central African Republic"},
                {name: "UNM49:148", label: "Chad"},
                {name: "UNM49:152", label: "Chile"},
                {name: "UNM49:156", label: "China"},
                {name: "UNM49:162", label: "Christmas Island"},
                {name: "UNM49:166", label: "Cocos (Keeling) Islands"},
                {name: "UNM49:170", label: "Colombia"},
                {name: "UNM49:174", label: "Comoros"},
                {name: "UNM49:178", label: "Congo"},
                {name: "UNM49:184", label: "Cook Islands"},
                {name: "UNM49:188", label: "Costa Rica"},
                {name: "UNM49:384", label: "Côte d’Ivoire"},
                {name: "UNM49:191", label: "Croatia"},
                {name: "UNM49:192", label: "Cuba"},
                {name: "UNM49:531", label: "Curaçao"},
                {name: "UNM49:196", label: "Cyprus"},
                {name: "UNM49:203", label: "Czechia"},
                {name: "UNM49:408", label: "Democratic People's Republic of Korea"},
                {name: "UNM49:180", label: "Democratic Republic of the Congo"},
                {name: "UNM49:208", label: "Denmark"},
                {name: "UNM49:262", label: "Djibouti"},
                {name: "UNM49:212", label: "Dominica"},
                {name: "UNM49:214", label: "Dominican Republic"},
                {name: "UNM49:218", label: "Ecuador"},
                {name: "UNM49:818", label: "Egypt"},
                {name: "UNM49:222", label: "El Salvador"},
                {name: "UNM49:226", label: "Equatorial Guinea"},
                {name: "UNM49:232", label: "Eritrea"},
                {name: "UNM49:233", label: "Estonia"},
                {name: "UNM49:748", label: "Eswatini"},
                {name: "UNM49:231", label: "Ethiopia"},
                {name: "UNM49:238", label: "Falkland Islands (Malvinas)"},
                {name: "UNM49:234", label: "Faroe Islands"},
                {name: "UNM49:242", label: "Fiji"},
                {name: "UNM49:246", label: "Finland"},
                {name: "UNM49:250", label: "France"},
                {name: "UNM49:254", label: "French Guiana"},
                {name: "UNM49:258", label: "French Polynesia"},
                {name: "UNM49:260", label: "French Southern Territories"},
                {name: "UNM49:266", label: "Gabon"},
                {name: "UNM49:270", label: "Gambia"},
                {name: "UNM49:268", label: "Georgia"},
                {name: "UNM49:276", label: "Germany"},
                {name: "UNM49:288", label: "Ghana"},
                {name: "UNM49:292", label: "Gibraltar"},
                {name: "UNM49:300", label: "Greece"},
                {name: "UNM49:304", label: "Greenland"},
                {name: "UNM49:308", label: "Grenada"},
                {name: "UNM49:312", label: "Guadeloupe"},
                {name: "UNM49:316", label: "Guam"},
                {name: "UNM49:320", label: "Guatemala"},
                {name: "UNM49:831", label: "Guernsey"},
                {name: "UNM49:324", label: "Guinea"},
                {name: "UNM49:624", label: "Guinea-Bissau"},
                {name: "UNM49:328", label: "Guyana"},
                {name: "UNM49:332", label: "Haiti"},
                {name: "UNM49:334", label: "Heard Island and McDonald Islands"},
                {name: "UNM49:336", label: "Holy See"},
                {name: "UNM49:340", label: "Honduras"},
                {name: "UNM49:344", label: "Hong Kong"},
                {name: "UNM49:348", label: "Hungary"},
                {name: "UNM49:352", label: "Iceland"},
                {name: "UNM49:356", label: "India"},
                {name: "UNM49:360", label: "Indonesia"},
                {name: "UNM49:364", label: "Iran (Islamic Republic of)"},
                {name: "UNM49:368", label: "Iraq"},
                {name: "UNM49:372", label: "Ireland"},
                {name: "UNM49:833", label: "Isle of Man"},
                {name: "UNM49:376", label: "Israel"},
                {name: "UNM49:380", label: "Italy"},
                {name: "UNM49:388", label: "Jamaica"},
                {name: "UNM49:392", label: "Japan"},
                {name: "UNM49:832", label: "Jersey"},
                {name: "UNM49:400", label: "Jordan"},
                {name: "UNM49:398", label: "Kazakhstan"},
                {name: "UNM49:404", label: "Kenya"},
                {name: "UNM49:296", label: "Kiribati"},
                {name: "UNM49:410", label: "Korea (the Republic of)"},
                {name: "UNM49:414", label: "Kuwait"},
                {name: "UNM49:417", label: "Kyrgyzstan"},
                {name: "UNM49:418", label: "Lao People's Democratic Republic"},
                {name: "UNM49:428", label: "Latvia"},
                {name: "UNM49:422", label: "Lebanon"},
                {name: "UNM49:426", label: "Lesotho"},
                {name: "UNM49:430", label: "Liberia"},
                {name: "UNM49:434", label: "Libya"},
                {name: "UNM49:438", label: "Liechtenstein"},
                {name: "UNM49:440", label: "Lithuania"},
                {name: "UNM49:442", label: "Luxembourg"},
                {name: "UNM49:446", label: "Macao"},
                {name: "UNM49:450", label: "Madagascar"},
                {name: "UNM49:454", label: "Malawi"},
                {name: "UNM49:458", label: "Malaysia"},
                {name: "UNM49:462", label: "Maldives"},
                {name: "UNM49:466", label: "Mali"},
                {name: "UNM49:470", label: "Malta"},
                {name: "UNM49:584", label: "Marshall Islands"},
                {name: "UNM49:474", label: "Martinique"},
                {name: "UNM49:478", label: "Mauritania"},
                {name: "UNM49:480", label: "Mauritius"},
                {name: "UNM49:175", label: "Mayotte"},
                {name: "UNM49:484", label: "Mexico"},
                {name: "UNM49:583", label: "Micronesia (Federated States of)"},
                {name: "UNM49:498", label: "Moldova"},
                {name: "UNM49:492", label: "Monaco"},
                {name: "UNM49:496", label: "Mongolia"},
                {name: "UNM49:499", label: "Montenegro"},
                {name: "UNM49:500", label: "Montserrat"},
                {name: "UNM49:504", label: "Morocco"},
                {name: "UNM49:508", label: "Mozambique"},
                {name: "UNM49:104", label: "Myanmar"},
                {name: "UNM49:516", label: "Namibia"},
                {name: "UNM49:520", label: "Nauru"},
                {name: "UNM49:524", label: "Nepal"},
                {name: "UNM49:528", label: "Netherlands"},
                {name: "UNM49:540", label: "New Caledonia"},
                {name: "UNM49:554", label: "New Zealand"},
                {name: "UNM49:558", label: "Nicaragua"},
                {name: "UNM49:562", label: "Niger"},
                {name: "UNM49:566", label: "Nigeria"},
                {name: "UNM49:570", label: "Niue"},
                {name: "UNM49:574", label: "Norfolk Island"},
                {name: "UNM49:807", label: "North Macedonia"},
                {name: "UNM49:580", label: "Northern Mariana Islands"},
                {name: "UNM49:578", label: "Norway"},
                {name: "UNM49:512", label: "Oman"},
                {name: "UNM49:586", label: "Pakistan"},
                {name: "UNM49:585", label: "Palau"},
                {name: "UNM49:591", label: "Panama"},
                {name: "UNM49:598", label: "Papua New Guinea"},
                {name: "UNM49:600", label: "Paraguay"},
                {name: "UNM49:604", label: "Peru"},
                {name: "UNM49:608", label: "Philippines"},
                {name: "UNM49:612", label: "Pitcairn"},
                {name: "UNM49:616", label: "Poland"},
                {name: "UNM49:620", label: "Portugal"},
                {name: "UNM49:630", label: "Puerto Rico"},
                {name: "UNM49:634", label: "Qatar"},
                {name: "UNM49:638", label: "Réunion"},
                {name: "UNM49:642", label: "Romania"},
                {name: "UNM49:643", label: "Russia"},
                {name: "UNM49:646", label: "Rwanda"},
                {name: "UNM49:652", label: "Saint Barthélemy"},
                {name: "UNM49:654", label: "Saint Helena"},
                {name: "UNM49:659", label: "Saint Kitts and Nevis"},
                {name: "UNM49:662", label: "Saint Lucia"},
                {name: "UNM49:663", label: "Saint Martin (French Part)"},
                {name: "UNM49:666", label: "Saint Pierre and Miquelon"},
                {name: "UNM49:670", label: "Saint Vincent and the Grenadines"},
                {name: "UNM49:882", label: "Samoa"},
                {name: "UNM49:674", label: "San Marino"},
                {name: "UNM49:678", label: "Sao Tome and Principe"},
                {name: "UNM49:680", label: "Sark"},
                {name: "UNM49:682", label: "Saudi Arabia"},
                {name: "UNM49:686", label: "Senegal"},
                {name: "UNM49:688", label: "Serbia"},
                {name: "UNM49:690", label: "Seychelles"},
                {name: "UNM49:694", label: "Sierra Leone"},
                {name: "UNM49:702", label: "Singapore"},
                {name: "UNM49:534", label: "Sint Maarten (Dutch part)"},
                {name: "UNM49:703", label: "Slovakia"},
                {name: "UNM49:705", label: "Slovenia"},
                {name: "UNM49:090", label: "Solomon Islands"},
                {name: "UNM49:706", label: "Somalia"},
                {name: "UNM49:710", label: "South Africa"},
                {name: "UNM49:239", label: "South Georgia and the South Sandwich Islands"},
                {name: "UNM49:728", label: "South Sudan"},
                {name: "UNM49:724", label: "Spain"},
                {name: "UNM49:144", label: "Sri Lanka"},
                {name: "UNM49:275", label: "State of Palestine"},
                {name: "UNM49:729", label: "Sudan"},
                {name: "UNM49:740", label: "Suriname"},
                {name: "UNM49:744", label: "Svalbard and Jan Mayen Islands"},
                {name: "UNM49:752", label: "Sweden"},
                {name: "UNM49:756", label: "Switzerland"},
                {name: "UNM49:760", label: "Syrian Arab Republic"},
                {name: "UNM49:762", label: "Tajikistan"},
                {name: "UNM49:834", label: "Tanzania"},
                {name: "UNM49:764", label: "Thailand"},
                {name: "UNM49:626", label: "Timor-Leste"},
                {name: "UNM49:768", label: "Togo"},
                {name: "UNM49:772", label: "Tokelau"},
                {name: "UNM49:776", label: "Tonga"},
                {name: "UNM49:780", label: "Trinidad and Tobago"},
                {name: "UNM49:788", label: "Tunisia"},
                {name: "UNM49:792", label: "Turkey"},
                {name: "UNM49:795", label: "Turkmenistan"},
                {name: "UNM49:796", label: "Turks and Caicos Islands"},
                {name: "UNM49:798", label: "Tuvalu"},
                {name: "UNM49:800", label: "Uganda"},
                {name: "UNM49:804", label: "Ukraine"},
                {name: "UNM49:784", label: "United Arab Emirates"},
                {name: "UNM49:826", label: "United Kingdom of Great Britain and Northern Ireland"},
                {name: "UNM49:581", label: "United States Minor Outlying Islands"},
                {name: "UNM49:840", label: "United States of America"},
                {name: "UNM49:850", label: "United States Virgin Islands"},
                {name: "UNM49:858", label: "Uruguay"},
                {name: "UNM49:860", label: "Uzbekistan"},
                {name: "UNM49:548", label: "Vanuatu"},
                {name: "UNM49:862", label: "Venezuela (Bolivarian Republic of)"},
                {name: "UNM49:704", label: "Viet Nam"},
                {name: "UNM49:876", label: "Wallis and Futuna Islands"},
                {name: "UNM49:732", label: "Western Sahara"},
                {name: "UNM49:887", label: "Yemen"},
                {name: "UNM49:894", label: "Zambia"},
                {name: "UNM49:716", label: "Zimbabwe"}
            ]
        },

        {name: 'provider', label: 'Provider', operators: [{ name: '=', label: 'is' },{ name: '!=', label: 'is not' }],
            valueEditorType: 'select', values:[
                //{name: "CGIAR-SYSTEM", label: "CGIAR - Consortium of International Agricultural Research Centers"},
                {name: "CGIAR-CRP-PTF", label: "CGIAR - Research Programs and Platforms"},
                //---------------------------------------------------
                {name: "AfricaRice", label: "Africa Rice Center"},
                {name: "Bioversity", label: "Bioversity International"},
                {name: "Bioversity-CIAT", label: "Bioversity-CIAT Alliance"},
                {name: "CIAT", label: "CIAT - International Center for Tropical Agriculture"},
                {name: "CIFOR", label: "CIFOR - Center for International Forestry Research"},
                {name: "CIMMYT", label: "CIMMYT - International Maize and Wheat Improvement Center"},
                {name: "CIP", label: "CIP - International Potato Center"},
                {name: "ICARDA", label: "ICARDA - International Center for Agricultural Research in the Dry Areas"},
                {name: "ICRAF", label: "ICRAF - World Agroforestry Centre"},
                {name: "ICRISAT", label: "ICRISAT - International Crops Research Institute for the Semi-Arid Tropics"},
                {name: "IFPRI", label: "IFPRI - International Food Policy Research Institute"},
                {name: "IITA", label: "IITA - International Institute of Tropical Agriculture"},
                {name: "ILRI", label: "ILRI - International Livestock Research Institute"},
                {name: "IRRI", label: "IRRI - International Rice Research Institute"},
                {name: "IWMI", label: "IWMI - International Water Management Institute"},
                {name: "WorldFish", label: "WorldFish"},
                //---------------------------------------------------
                {name: "CTA", label: "CTA - Technical Centre for Agricultural and Rural Cooperation"},
                {name: "IndiaGOV", label: "Government of India (IndiaGOV)"},
                {name: "ICAR", label: "ICAR - Indian Council of Agricultural Research"},
                {name: "IGAD", label: "IGAD - Intergovernmental Authority on Development"},
                {name: "PABRA", label: "PABRA - Pan-Africa Bean Research Alliance"},
                {name: "FCDO", label: "UK Foreign, Commonwealth & Development Office (FCDO)"},
                {name: "USAID-FTF", label: "USAID - Feed the Future"}
            ]
        },

        { name: 'author', label: 'Author', operators: [{ name: '=', label: 'is' },{ name: 'contains', label: 'contains' }], validator: ({ value }) => testEmpty(value)},

        { name: 'release_year', label: 'Release Year', inputType: 'number', operators: [{ name: '=', label: 'is' },{ name: '!=', label: 'is not' },{ name: '>=', label: 'is greater than or equal to' },{ name: '<=', label: 'is less than or equal to' }],
            defaultValue: new Date().getFullYear(),
            validator: ({ value }) => testYear(value)
        },

        { name: 'temporal_coverage', label: 'Temporal Coverage', inputType: 'number', operators: [{ name: '=', label: 'is' },{ name: '!=', label: 'is not' },{ name: '>=', label: 'is greater than or equal to' },{ name: '<=', label: 'is less than or equal to' }],
            defaultValue: new Date().getFullYear(),
            validator: ({ value }) => testYear(value)
        },

        {name: 'funder', label: 'Funder / Investor', operators: [{ name: '=', label: 'is' },{ name: 'contains', label: 'contains' }], validator: ({ value }) => testEmpty(value)},


        {name: 'project', label: 'Project / Platform', operators: [{ name: '=', label: 'is' },{ name: '!=', label: 'is not' }],
            valueEditorType: 'select', values:[
                {name: "A4NH", label: "CRP - Agriculture for Nutrition and Health (A4NH)"},
                {name: "AAS", label: "CRP - Aquatic Agricultural Systems (AAS)"},
                {name: "CCAFS", label: "CRP - Climate Change, Agriculture and Food Security (CCAFS)"},
                {name: "DC", label: "CRP - Dryland Cereals (DC)"},
                {name: "Dryland", label: "CRP - Dryland Agricultural Production Systems (Dryland)"},
                {name: "Fish", label: "CRP - Fish"},
                {name: "FTA", label: "CRP - Forests, Trees and Agroforestry (FTA)"},
                {name: "GL", label: "CRP - Grain Legumes (GL)"},
                {name: "GLDC", label: "CRP - Grain Legumes and Dryland Cereals (GLDC)"},
                {name: "Humidtropics", label: "CRP - Integrated Systems for the Humid Tropics (Humidtropics)"},
                {name: "Livestock", label: "CRP - Livestock"},
                {name: "L&F", label: "CRP - Livestock and Fish"},
                {name: "Maize", label: "CRP - Maize"},
                {name: "PIM", label: "CRP - Policies, Institutions, and Markets (PIM)"},
                {name: "Rice", label: "CRP - Rice"},
                {name: "RTB", label: "CRP - Roots, Tubers and Bananas (RTB)"},
                {name: "Wheat", label: "CRP - Wheat"},
                {name: "WLE", label: "CRP - Water, Land and Ecosystems (WLE)"},
                {name: "EiB", label: "PTF - Excellence in Breeding Platform (EiB)"},
                {name: "BigData", label: "PTF - Platform for Big Data in Agriculture (BigData)"},
                {name: "Gender", label: "PTF - Gender Platform (Gender)"},
                {name: "Genebank", label: "PTF - Genebank Platform (Genebank)"}

            ]
        }
    ]

    const preparedTranslations  = {
        fields: {
            title: "Fields",
        },
        operators: {
            title: "Operators",
        },
        value: {
            title: "Value",
        },
        removeRule: {
            label: <i class="far fa-times"></i>,
            title: "Remove rule",
        },
        removeGroup: {
            label: <i class="far fa-times"></i>,
            title: "Remove group",
        },
        addRule: {
            label: <span><i class="far fa-plus"/> Rule</span> ,
            title: "Add rule",
        },
        addGroup: {
            label: <span><i class="far fa-plus"></i> Group</span>,
            title: "Add group",
        },
        combinators: {
            title: "Combinators",
        },
        notToggle: {
            title: "Invert this group",
        }
    }

    const advancedSearchOpened = (openState) => {
        setPanelCollapsed(openState);
        if(openState){
            setSearchQuery(userQuery);
        }
    }


    const stopwords = [ "a", "about", "above", "after", "again", "against", "all", "am", "an", "and", "any", "are", "as", "at", "be", "because", "been", "before", "being",
        "below", "between", "both", "but", "by", "can", "did", "do", "does", "doing", "don", "down", "during", "each", "few", "find", "for", "from", "further",
        "had", "has", "have", "having", "he", "her", "here", "hers", "him", "himself", "his", "how", "if", "in", "into", "is", "it", "its", "itself",
        "just", "me", "more", "most", "my", "myself", "no", "nor", "not", "now", "of", "off", "on", "once", "only", "or", "our", "ours", "ourselves",
        "over", "own", "s", "same", "she", "should", "so", "some", "such", "t", "than", "that", "the", "their", "theirs", "them", "themselves",
        "then", "there", "these", "they", "this", "those", "through", "to", "too", "under", "until", "up", "very", "we", "were", "what", "when",
        "where", "which", "who", "whom", "why", "will", "with", "you", "your", "yours", "yourself", "yourselves", "a", "about", "above", "after",
        "again", "against", "all", "am", "an", "and", "any", "are", "aren", "as", "at", "be", "because", "been", "before", "being", "below", "between",
        "both", "but", "by", "can", "cannot", "could", "couldn", "d", "did", "didn", "do", "does", "doesn", "doing", "don", "down", "during", "each",
        "few", "for", "from", "further", "had", "hadn", "has", "hasn", "have", "haven", "having", "he", "her", "here", "hers", "herself", "him", "himself",
        "his", "how", "i", "if", "in", "into", "is", "isn", "it", "its", "itself", "let", "ll", "m", "me", "more", "most", "must", "mustn", "my", "myself",
        "no", "nor", "not", "of", "off", "on", "once", "only", "or", "other", "ought", "our", "ours", "ourselves", "out", "over", "own", "re", "s", "same",
        "shan", "she", "should", "shouldn", "so", "some", "such", "t", "than", "that", "the", "their", "theirs", "them", "themselves", "then", "there",
        "these", "they", "this", "those", "through", "to", "too", "under", "until", "up", "ve", "very", "was", "wasn", "want", "we", "were", "weren", "what",
        "when", "where", "which", "while", "who", "whom", "why", "with", "won", "would", "wouldn", "you", "your", "yours", "yourself", "yourselves" ];


    function remove_stopwords(str) {
        let res = []
        let words = str.split(/(?:,| |')+/);

        for(let i=0;i<words.length;i++) {
            let word_clean = words[i].split(".").join("")
            if(!stopwords.includes(word_clean)) {
                res.push(word_clean)
            }
        }
        return(res.join(' '))
    }


    const initQuery = (e) => {

        let userQueryStr = e.target.value;
        let cleanStr = userQueryStr.trim();

        setSearchQuery(e.target.value);
        setUserQuery(e.target.value);

        if(panelCollapsed){

            if (cleanStr==="") {

                setQuery({
                    rules: [
                        {
                            field: 'allFields',
                            value: '',
                            operator: 'contains'
                        }
                    ],
                    combinator: 'and',
                    not: false
                });

            }else{

                let jsonQuery = "{\"rules\":[";

                let tempstr = (e.target.value).replace(/\"/g, "\\\"");
                while (tempstr.includes("\\\\")) { tempstr = tempstr.replace(/\\\\/g, "\\"); }

                let nodoublequotes = true;
                if (userQueryStr.indexOf("\"") !== userQueryStr.lastIndexOf("\"")) {
                    nodoublequotes = false;
                }

                //----------------------------------- FIX STOP WORDS

                let userQueryStrWithoutStopWords = remove_stopwords(userQueryStr.toLowerCase());

                //-----------------------------------
                let uniphrases = userQueryStrWithoutStopWords.trim().split("\"");

                //let uniphrases = userQueryStr.trim().split("\"");

                let isFirst = true;

                // console.log(uniphrases)

                for(let j = 0; j < uniphrases.length; j++){

                    if(uniphrases[j] !== ""){
                        if ((nodoublequotes) || (uniphrases[j].trim().length !== uniphrases[j].length)) {

                            let res = uniphrases[j].trim().split(" ");

                            for(let i = 0; i < res.length; i++){

                                if(res[i] !== ""){

                                    let xres = res[i].replace(/\"/g, "");

                                    if(isFirst){
                                        isFirst = false;
                                        jsonQuery += "{\"field\":\"allFields\",\"value\":\"" + xres + "\",\"operator\": \"contains\"}";

                                    }else{
                                        jsonQuery += ",{\"field\":\"allFields\",\"value\":\"" + xres + "\",\"operator\": \"contains\"}";
                                    }
                                }
                            }

                        }
                        else {
                            if(isFirst){
                                isFirst = false;
                                jsonQuery += "{\"field\":\"allFields\",\"value\":\"" + uniphrases[j] + "\",\"operator\": \"contains\"}";
                            }else{
                                jsonQuery += ",{\"field\":\"allFields\",\"value\":\"" + uniphrases[j] + "\",\"operator\": \"contains\"}";
                            }
                        }
                    }
                }

                jsonQuery += "],\"combinator\": \"and\",\"not\": false}";

                setQuery(JSON.parse(jsonQuery));

                setQueryPath("/search-results/0/" +
                    "{\"user_query\":\"" + tempstr +
                    "\",\"json_query\":" + formatQuery(JSON.parse(jsonQuery), 'json_without_ids').replace(/\r/g,'')+"}");

            }

        }


    }


    const handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            history.push(queryPath);
        }
    }

    const runAdvancedSearch = () => {
        setUserQuery("");
        setPanelCollapsed(false);
        //-------------------------------
        let tempQP = "/search-results/0/{\"user_query\":\"\",\"json_query"
        let zt   = "json_query";
        //-------------------------------
        let aqp = tempQP + queryPath.substring(queryPath.indexOf(zt)+zt.length, queryPath.length);
        //-------------------------------
        history.push(aqp);
    }

    return (
        <>
            <div className="p-grid">
                <div className="p-col">
                    <div>
                        <span className="p-input-icon-right" style={{width: '100%'}}>
                            <InputText className={panelCollapsed? 'input-search' : 'input-search-hidden' } type="text" placeholder="Search GARDIAN Resources" onKeyDown={handleSearchKeyDown} onChange={initQuery} value={searchQuery} readOnly={!panelCollapsed} visible={!panelCollapsed} />
                            <i className="pi pi-search" />
                        </span>
                    </div>
                </div>
            </div>


            <Fieldset className="fieldset-advanced-search" legend="Advanced Search" toggleable collapsed={panelCollapsed}

                      style={{
                          display: enableAdvancedSearch ? 'block' : 'none'
                      }}

                      onToggle={(e) => {advancedSearchOpened(e.value)}}>
                <div className="p-grid">
                    <div className="p-col">
                        <div className="with-bootstrap">
                            <QueryBuilder query={query}
                                          fields={fields}
                                          addRuleToNewGroups
                                          validator={defaultValidator}
                                          controlClassnames="with-bootstrap"
                                          translations={preparedTranslations}
                                          onQueryChange={logQuery}
                                          controlElements="with-bootstrap"/>
                        </div>
                    </div>
                    <div className="p-col-fixed search-buttons-container">
                        {   enableSearch?
                            <div>
                                <Button icon="pi pi-search" className="search-btn" label="Search" style={{width:"100px", marginBottom:"20px"}} onClick={(e) => runAdvancedSearch()}></Button>
                            </div>
                            :
                            <>
                              <div>
                                  <Button icon="pi pi-search" className="search-btn" label="Search" style={{width:"100px", marginBottom:"20px", color: '#ababab', backgroundColor: '#d6d6d6', cursor: 'not-allowed'}}></Button>
                              </div>
                            </>
                        }
                        <SearchGuide></SearchGuide>
                    </div>
                </div>
            </Fieldset>

        </>
    );
}
