import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';

import AppBreadcrumb from './AppBreadcrumb';
import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import {Search} from "./gardian/pages/Search";
import {Home} from "./gardian/pages/Home";
import {SearchResults} from "./gardian/pages/SearchResults";
import {AssetInfo} from "./gardian/pages/AssetInfo";
import {Analytics} from "./gardian/pages/Analytics";
import {FairMetrics} from "./gardian/pages/FairMetrics";
import {About} from "./gardian/pages/About";
import {Tools} from "./gardian/pages/Tools";
import {APIs} from "./gardian/pages/APIs";
import {MapsPage} from "./gardian/pages/MapsPage";

const App = () => {

    const [horizontal, setHorizontal] = useState(true);
    const [topbarSize, setTopbarSize] = useState('medium');
    const [topbarColor, setTopbarColor] = useState('layout-topbar-river');
    const [menuColor, setMenuColor] = useState('layout-menu-light');
    const [menuActive, setMenuActive] = useState(false);
    const [menuHoverActive, setMenuHoverActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);

    PrimeReact.ripple = true;

    const menu = {
        leftMenu: [
            {label: 'Home', to: '/'},
            {label: 'Search', to: '/search'},
            {label: 'Maps', to: '/maps'},
            {label: 'Tools', to: '/tools'},
            {label: 'Analytics', to: '/analytics'}
           // {label: 'APIs', to: '/api'}
        ],
        rightMenu: [
            {label: 'About', to: '/about'},
        ]
    };

    const routers = [
        { path: '/',  label: 'Dashboard', component: Home, exact: true },
        //-------------------
        { path: '/search', label: 'Search', component: Search },
        { path: '/search-results/:tab/:query', label: 'Search Results', component: SearchResults },
        //-------------------
        { path: '/asset/:id', label: 'AssetInfo' , component: AssetInfo },
        //-------------------
        { path: '/analytics/:tab?/:prov?', label: 'Analytics', component: Analytics },
        { path: '/tools/:tab?', label: 'Tools', component: Tools },
        { path: '/api', label: 'API', component: APIs },
        //-------------------
        { path: '/fair', label: 'FAIR Metrics', component: FairMetrics },
        //-------------------
        { path: '/about', label: 'About', component: About },
        //-------------------
        { path: '/maps', label: 'Maps', component: MapsPage }
    ];

    let menuClick;
    let userMenuClick;

    const onWrapperClick = () => {
        if (!menuClick) {
            setMenuActive(false)
            unblockBodyScroll();

            if (horizontal) {
                setMenuHoverActive(false);
            }
        }

        if (!userMenuClick) {
            setTopbarUserMenuActive(false);
        }

        userMenuClick = false;
        menuClick = false;
    };

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (!horizontal || isMobile()) {
            setMenuActive(prevMenuActive => !prevMenuActive);
        }

        event.preventDefault();
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    const onTopbarUserMenuButtonClick = (event) => {
        userMenuClick = true;
        setTopbarUserMenuActive(prevTopbarUserMenuActive => !prevTopbarUserMenuActive);

        event.preventDefault();
    };

    const onTopbarUserMenuClick = (event) => {
        userMenuClick = true;

        if (event.target.nodeName === 'BUTTON' || event.target.parentNode.nodeName === 'BUTTON') {
            setTopbarUserMenuActive(false)
        }
        event.preventDefault();
    };

    const onRootMenuItemClick = (event) => {
        menuClick = true;
        if (horizontal && isMobile()) {
            setMenuHoverActive(event.isSameIndex ? false : true);
        }
        else {
            setMenuHoverActive(prevMenuHoverActive => !prevMenuHoverActive);
        }
    };

    const onMenuItemClick = (event) => {
        if (event.item && !event.item.items) {
            if (!horizontal || isMobile()) {
                setMenuActive(false);
                unblockBodyScroll();
            }

            setMenuHoverActive(false);
        }
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const isMobile = () => {
        return window.innerWidth <= 1024;
    };

    useEffect(() => {
        if (menuActive) {
            blockBodyScroll();
        }
        else {
            unblockBodyScroll();
        }
    }, [menuActive]);

    const layoutContainerClassName = classNames('layout-container', {
        'layout-menu-horizontal': horizontal,
        'layout-menu-active': menuActive,
        'layout-top-small': topbarSize === 'small',
        'layout-top-medium': topbarSize === 'medium',
        'layout-top-large': topbarSize === 'large',
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple,
    }, topbarColor, menuColor);

    return (
        <div className={layoutContainerClassName} onClick={onWrapperClick}>
            <div className="layout-top">
                <AppTopbar topbarUserMenuActive={topbarUserMenuActive} menuActive={menuActive} menuHoverActive={menuHoverActive}
                    onMenuButtonClick={onMenuButtonClick} onTopbarUserMenuButtonClick={onTopbarUserMenuButtonClick}
                    onTopbarUserMenuClick={onTopbarUserMenuClick} model={menu} horizontal={horizontal} onSidebarClick={onSidebarClick}
                    onRootMenuItemClick={onRootMenuItemClick} onMenuItemClick={onMenuItemClick} isMobile={isMobile} />

                <div className="layout-topbar-separator" />

                <AppBreadcrumb routers={routers}/>
            </div>

            <div className="layout-content">
                {
                    routers.map((router, index) => {
                        if (router.exact) {
                            return <Route key={`router${index}`} path={router.path} exact component={router.component} />
                        }

                        return <Route key={`router${index}`} path={router.path} component={router.component} />
                    })
                }
            </div>

            {/*<AppConfig topbarSize={topbarSize} onTopbarSizeChange={onTopbarSizeChange}*/}
            {/*    topbarColor={topbarColor} onTopbarThemeChange={onTopbarThemeChange}*/}
            {/*    horizontal={horizontal} onMenuToHorizontalChange={onMenuToHorizontalChange}*/}
            {/*    menuColor={menuColor} onMenuThemeChange={onMenuThemeChange}*/}
            {/*    themeColor={themeColor} onThemeColorChange={onThemeColorChange}*/}
            {/*    layoutColor={layoutColor} onLayoutColorChange={onLayoutColorChange}*/}
            {/*    compactMode={compactMode} onCompactModeChange={onCompactModeChange}*/}
            {/*    rippleActive={ripple} onRippleChange={onRippleChange}*/}
            {/*    inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} />*/}

            <AppFooter />

            {menuActive && <div className="layout-mask" />}
        </div>
    );

}

export default App;
