import React, {useEffect, useRef, useState} from "react";
import {Column} from "primereact/column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Button} from 'primereact/button';
import {Tooltip} from 'primereact/tooltip';
import {MultiSelect} from "primereact/multiselect";
import {ResultsAnalytics} from "./ResultsAnalytics";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";

export const ResultsTable = props => {
    const [mainData, setMainData] = useState(null);

    const [exportResults, setExportResults] = useState(null);

    const [selectedResourceType, setSelectedResourceType] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [yearValue,setYearValue] = useState('');
    const [titleValue,setTitleValue] = useState('');
    const dt = useRef(null);

    const [resultsDATA,setResultsDATA]= useState(null);
    const [fullQUERY,setFullQUERY]= useState(null);
    const [urlPath,setUrlPath]= useState("");

    const [total, setTotal] = useState(null);




    const varPROJECTS = [
        {name: "A4NH", label: "CRP - Agriculture for Nutrition and Health (A4NH)"},
        {name: "AAS", label: "CRP - Aquatic Agricultural Systems (AAS)"},
        {name: "CCAFS", label: "CRP - Climate Change, Agriculture and Food Security (CCAFS)"},
        {name: "DC", label: "CRP - Dryland Cereals (DC)"},
        {name: "Dryland", label: "CRP - Dryland Agricultural Production Systems (Dryland)"},
        {name: "Fish", label: "CRP - Fish"},
        {name: "FTA", label: "CRP - Forests, Trees and Agroforestry (FTA)"},
        {name: "GL", label: "CRP - Grain Legumes (GL)"},
        {name: "GLDC", label: "CRP - Grain Legumes and Dryland Cereals (GLDC)"},
        {name: "Humidtropics", label: "CRP - Integrated Systems for the Humid Tropics (Humidtropics)"},
        {name: "Livestock", label: "CRP - Livestock"},
        {name: "L&F", label: "CRP - Livestock and Fish"},
        {name: "Maize", label: "CRP - Maize"},
        {name: "PIM", label: "CRP - Policies, Institutions, and Markets (PIM)"},
        {name: "Rice", label: "CRP - Rice"},
        {name: "RTB", label: "CRP - Roots, Tubers and Bananas (RTB)"},
        {name: "Wheat", label: "CRP - Wheat"},
        {name: "WLE", label: "CRP - Water, Land and Ecosystems (WLE)"},
        {name: "EiB", label: "PTF - Excellence in Breeding Platform (EiB)"},
        {name: "BigData", label: "PTF - Platform for Big Data in Agriculture (BigData)"},
        {name: "Gender", label: "PTF - Gender Platform (Gender)"},
        {name: "Genebank", label: "PTF - Genebank Platform (Genebank)"}
    ];

    const varPROVIDERS = [
        {name: "CGIAR-SYSTEM", label: "CGIAR - Consortium of International Agricultural Research Centers"},
        {name: "CGIAR-CRP-PTF", label: "CGIAR - Research Programs and Platforms"},
        //---------------------------------------------------
        {name: "AfricaRice", label: "Africa Rice Center"},
        {name: "Bioversity", label: "Bioversity International"},
        {name: "Bioversity-CIAT", label: "Bioversity-CIAT Alliance"},
        {name: "CIAT", label: "International Center for Tropical Agriculture (CIAT)"},
        {name: "CIFOR", label: "Center for International Forestry Research (CIFOR)"},
        {name: "CIMMYT", label: "International Maize and Wheat Improvement Center (CIMMYT)"},
        {name: "CIP", label: "International Potato Center (CIP)"},
        {name: "ICARDA", label: "International Center for Agricultural Research in the Dry Areas (ICARDA)"},
        {name: "ICRAF", label: "World Agroforestry Centre (ICRAF)"},
        {name: "ICRISAT", label: "International Crops Research Institute for the Semi-Arid Tropics (ICRISAT)"},
        {name: "IFPRI", label: "International Food Policy Research Institute (IFPRI)"},
        {name: "IITA", label: "International Institute of Tropical Agriculture (IITA)"},
        {name: "ILRI", label: "International Livestock Research Institute (ILRI)"},
        {name: "IRRI", label: "International Rice Research Institute (IRRI)"},
        {name: "IWMI", label: "International Water Management Institute (IWMI)"},
        {name: "WorldFish", label: "WorldFish"},
        //---------------------------------------------------
        {name: "USAID-FTF", label: "USAID - Feed the Future"},
        {name: "PABRA", label: "Pan-Africa Bean Research Alliance (PABRA)"},
        {name: "CTA", label: "Technical Centre for Agricultural and Rural Cooperation (CTA)"},
        {name: "IGAD", label: "Intergovernmental Authority on Development (IGAD)"},
        //---------------------------------------------------
        {name: "ICAR", label: "Indian Council of Agricultural Research (ICAR)"}

    ];

    const varCOUNTRIES = [
        { "code_UNM49": "UNM49:004", "code_ISO3166_1_a2": "AF", "code_ISO3166_1_a3": "AFG", "value": "Afghanistan" },
        { "code_UNM49": "UNM49:008", "code_ISO3166_1_a2": "AL", "code_ISO3166_1_a3": "ALB", "value": "Albania" },
        { "code_UNM49": "UNM49:012", "code_ISO3166_1_a2": "DZ", "code_ISO3166_1_a3": "DZA", "value": "Algeria" },
        { "code_UNM49": "UNM49:016", "code_ISO3166_1_a2": "AS", "code_ISO3166_1_a3": "ASM", "value": "American Samoa" },
        { "code_UNM49": "UNM49:020", "code_ISO3166_1_a2": "AD", "code_ISO3166_1_a3": "AND", "value": "Andorra" },
        { "code_UNM49": "UNM49:024", "code_ISO3166_1_a2": "AO", "code_ISO3166_1_a3": "AGO", "value": "Angola" },
        { "code_UNM49": "UNM49:028", "code_ISO3166_1_a2": "AG", "code_ISO3166_1_a3": "ATG", "value": "Antigua and Barbuda" },
        { "code_UNM49": "UNM49:031", "code_ISO3166_1_a2": "AZ", "code_ISO3166_1_a3": "AZE", "value": "Azerbaijan" },
        { "code_UNM49": "UNM49:032", "code_ISO3166_1_a2": "AR", "code_ISO3166_1_a3": "ARG", "value": "Argentina" },
        { "code_UNM49": "UNM49:036", "code_ISO3166_1_a2": "AU", "code_ISO3166_1_a3": "AUS", "value": "Australia" },
        { "code_UNM49": "UNM49:040", "code_ISO3166_1_a2": "AT", "code_ISO3166_1_a3": "AUT", "value": "Austria" },
        { "code_UNM49": "UNM49:044", "code_ISO3166_1_a2": "BS", "code_ISO3166_1_a3": "BHS", "value": "Bahamas" },
        { "code_UNM49": "UNM49:048", "code_ISO3166_1_a2": "BH", "code_ISO3166_1_a3": "BHR", "value": "Bahrain" },
        { "code_UNM49": "UNM49:050", "code_ISO3166_1_a2": "BD", "code_ISO3166_1_a3": "BGD", "value": "Bangladesh" },
        { "code_UNM49": "UNM49:051", "code_ISO3166_1_a2": "AM", "code_ISO3166_1_a3": "ARM", "value": "Armenia" },
        { "code_UNM49": "UNM49:052", "code_ISO3166_1_a2": "BB", "code_ISO3166_1_a3": "BRB", "value": "Barbados" },
        { "code_UNM49": "UNM49:056", "code_ISO3166_1_a2": "BE", "code_ISO3166_1_a3": "BEL", "value": "Belgium" },
        { "code_UNM49": "UNM49:060", "code_ISO3166_1_a2": "BM", "code_ISO3166_1_a3": "BMU", "value": "Bermuda" },
        { "code_UNM49": "UNM49:064", "code_ISO3166_1_a2": "BT", "code_ISO3166_1_a3": "BTN", "value": "Bhutan" },
        { "code_UNM49": "UNM49:068", "code_ISO3166_1_a2": "BO", "code_ISO3166_1_a3": "BOL", "value": "Bolivia" },
        { "code_UNM49": "UNM49:070", "code_ISO3166_1_a2": "BA", "code_ISO3166_1_a3": "BIH", "value": "Bosnia and Herzegovina" },
        { "code_UNM49": "UNM49:072", "code_ISO3166_1_a2": "BW", "code_ISO3166_1_a3": "BWA", "value": "Botswana" },
        { "code_UNM49": "UNM49:074", "code_ISO3166_1_a2": "BV", "code_ISO3166_1_a3": "BVT", "value": "Bouvet Island" },
        { "code_UNM49": "UNM49:076", "code_ISO3166_1_a2": "BR", "code_ISO3166_1_a3": "BRA", "value": "Brazil" },
        { "code_UNM49": "UNM49:084", "code_ISO3166_1_a2": "BZ", "code_ISO3166_1_a3": "BLZ", "value": "Belize" },
        { "code_UNM49": "UNM49:086", "code_ISO3166_1_a2": "IO", "code_ISO3166_1_a3": "IOT", "value": "British Indian Ocean Territory" },
        { "code_UNM49": "UNM49:090", "code_ISO3166_1_a2": "SB", "code_ISO3166_1_a3": "SLB", "value": "Solomon Islands" },
        { "code_UNM49": "UNM49:092", "code_ISO3166_1_a2": "VG", "code_ISO3166_1_a3": "VGB", "value": "British Virgin Islands" },
        { "code_UNM49": "UNM49:096", "code_ISO3166_1_a2": "BN", "code_ISO3166_1_a3": "BRN", "value": "Brunei Darussalam" },
        { "code_UNM49": "UNM49:100", "code_ISO3166_1_a2": "BG", "code_ISO3166_1_a3": "BGR", "value": "Bulgaria" },
        { "code_UNM49": "UNM49:104", "code_ISO3166_1_a2": "MM", "code_ISO3166_1_a3": "MMR", "value": "Myanmar" },
        { "code_UNM49": "UNM49:108", "code_ISO3166_1_a2": "BI", "code_ISO3166_1_a3": "BDI", "value": "Burundi" },
        { "code_UNM49": "UNM49:112", "code_ISO3166_1_a2": "BY", "code_ISO3166_1_a3": "BLR", "value": "Belarus" },
        { "code_UNM49": "UNM49:116", "code_ISO3166_1_a2": "KH", "code_ISO3166_1_a3": "KHM", "value": "Cambodia" },
        { "code_UNM49": "UNM49:120", "code_ISO3166_1_a2": "CM", "code_ISO3166_1_a3": "CMR", "value": "Cameroon" },
        { "code_UNM49": "UNM49:124", "code_ISO3166_1_a2": "CA", "code_ISO3166_1_a3": "CAN", "value": "Canada" },
        { "code_UNM49": "UNM49:132", "code_ISO3166_1_a2": "CV", "code_ISO3166_1_a3": "CPV", "value": "Cabo Verde" },
        { "code_UNM49": "UNM49:136", "code_ISO3166_1_a2": "KY", "code_ISO3166_1_a3": "CYM", "value": "Cayman Islands" },
        { "code_UNM49": "UNM49:140", "code_ISO3166_1_a2": "CF", "code_ISO3166_1_a3": "CAF", "value": "Central African Republic" },
        { "code_UNM49": "UNM49:144", "code_ISO3166_1_a2": "LK", "code_ISO3166_1_a3": "LKA", "value": "Sri Lanka" },
        { "code_UNM49": "UNM49:148", "code_ISO3166_1_a2": "TD", "code_ISO3166_1_a3": "TCD", "value": "Chad" },
        { "code_UNM49": "UNM49:152", "code_ISO3166_1_a2": "CL", "code_ISO3166_1_a3": "CHL", "value": "Chile" },
        { "code_UNM49": "UNM49:156", "code_ISO3166_1_a2": "CN", "code_ISO3166_1_a3": "CHN", "value": "China" },
        { "code_UNM49": "UNM49:162", "code_ISO3166_1_a2": "CX", "code_ISO3166_1_a3": "CXR", "value": "Christmas Island" },
        { "code_UNM49": "UNM49:166", "code_ISO3166_1_a2": "CC", "code_ISO3166_1_a3": "CCK", "value": "Cocos (Keeling) Islands" },
        { "code_UNM49": "UNM49:170", "code_ISO3166_1_a2": "CO", "code_ISO3166_1_a3": "COL", "value": "Colombia" },
        { "code_UNM49": "UNM49:174", "code_ISO3166_1_a2": "KM", "code_ISO3166_1_a3": "COM", "value": "Comoros" },
        { "code_UNM49": "UNM49:175", "code_ISO3166_1_a2": "YT", "code_ISO3166_1_a3": "MYT", "value": "Mayotte" },
        { "code_UNM49": "UNM49:178", "code_ISO3166_1_a2": "CG", "code_ISO3166_1_a3": "COG", "value": "Congo" },
        { "code_UNM49": "UNM49:180", "code_ISO3166_1_a2": "CD", "code_ISO3166_1_a3": "COD", "value": "Democratic Republic of the Congo" },
        { "code_UNM49": "UNM49:184", "code_ISO3166_1_a2": "CK", "code_ISO3166_1_a3": "COK", "value": "Cook Islands" },
        { "code_UNM49": "UNM49:188", "code_ISO3166_1_a2": "CR", "code_ISO3166_1_a3": "CRI", "value": "Costa Rica" },
        { "code_UNM49": "UNM49:191", "code_ISO3166_1_a2": "HR", "code_ISO3166_1_a3": "HRV", "value": "Croatia" },
        { "code_UNM49": "UNM49:192", "code_ISO3166_1_a2": "CU", "code_ISO3166_1_a3": "CUB", "value": "Cuba" },
        { "code_UNM49": "UNM49:196", "code_ISO3166_1_a2": "CY", "code_ISO3166_1_a3": "CYP", "value": "Cyprus" },
        { "code_UNM49": "UNM49:203", "code_ISO3166_1_a2": "CZ", "code_ISO3166_1_a3": "CZE", "value": "Czechia" },
        { "code_UNM49": "UNM49:204", "code_ISO3166_1_a2": "BJ", "code_ISO3166_1_a3": "BEN", "value": "Benin" },
        { "code_UNM49": "UNM49:208", "code_ISO3166_1_a2": "DK", "code_ISO3166_1_a3": "DNK", "value": "Denmark" },
        { "code_UNM49": "UNM49:212", "code_ISO3166_1_a2": "DM", "code_ISO3166_1_a3": "DMA", "value": "Dominica" },
        { "code_UNM49": "UNM49:214", "code_ISO3166_1_a2": "DO", "code_ISO3166_1_a3": "DOM", "value": "Dominican Republic" },
        { "code_UNM49": "UNM49:218", "code_ISO3166_1_a2": "EC", "code_ISO3166_1_a3": "ECU", "value": "Ecuador" },
        { "code_UNM49": "UNM49:222", "code_ISO3166_1_a2": "SV", "code_ISO3166_1_a3": "SLV", "value": "El Salvador" },
        { "code_UNM49": "UNM49:226", "code_ISO3166_1_a2": "GQ", "code_ISO3166_1_a3": "GNQ", "value": "Equatorial Guinea" },
        { "code_UNM49": "UNM49:231", "code_ISO3166_1_a2": "ET", "code_ISO3166_1_a3": "ETH", "value": "Ethiopia" },
        { "code_UNM49": "UNM49:232", "code_ISO3166_1_a2": "ER", "code_ISO3166_1_a3": "ERI", "value": "Eritrea" },
        { "code_UNM49": "UNM49:233", "code_ISO3166_1_a2": "EE", "code_ISO3166_1_a3": "EST", "value": "Estonia" },
        { "code_UNM49": "UNM49:234", "code_ISO3166_1_a2": "FO", "code_ISO3166_1_a3": "FRO", "value": "Faroe Islands" },
        { "code_UNM49": "UNM49:238", "code_ISO3166_1_a2": "FK", "code_ISO3166_1_a3": "FLK", "value": "Falkland Islands (Malvinas)" },
        { "code_UNM49": "UNM49:239", "code_ISO3166_1_a2": "GS", "code_ISO3166_1_a3": "SGS", "value": "South Georgia and the South Sandwich Islands" },
        { "code_UNM49": "UNM49:242", "code_ISO3166_1_a2": "FJ", "code_ISO3166_1_a3": "FJI", "value": "Fiji" },
        { "code_UNM49": "UNM49:246", "code_ISO3166_1_a2": "FI", "code_ISO3166_1_a3": "FIN", "value": "Finland" },
        { "code_UNM49": "UNM49:248", "code_ISO3166_1_a2": "AX", "code_ISO3166_1_a3": "ALA", "value": "Aland Islands" },
        { "code_UNM49": "UNM49:250", "code_ISO3166_1_a2": "FR", "code_ISO3166_1_a3": "FRA", "value": "France" },
        { "code_UNM49": "UNM49:254", "code_ISO3166_1_a2": "GF", "code_ISO3166_1_a3": "GUF", "value": "French Guiana" },
        { "code_UNM49": "UNM49:258", "code_ISO3166_1_a2": "PF", "code_ISO3166_1_a3": "PYF", "value": "French Polynesia" },
        { "code_UNM49": "UNM49:260", "code_ISO3166_1_a2": "TF", "code_ISO3166_1_a3": "ATF", "value": "French Southern Territories" },
        { "code_UNM49": "UNM49:262", "code_ISO3166_1_a2": "DJ", "code_ISO3166_1_a3": "DJI", "value": "Djibouti" },
        { "code_UNM49": "UNM49:266", "code_ISO3166_1_a2": "GA", "code_ISO3166_1_a3": "GAB", "value": "Gabon" },
        { "code_UNM49": "UNM49:268", "code_ISO3166_1_a2": "GE", "code_ISO3166_1_a3": "GEO", "value": "Georgia" },
        { "code_UNM49": "UNM49:270", "code_ISO3166_1_a2": "GM", "code_ISO3166_1_a3": "GMB", "value": "Gambia" },
        { "code_UNM49": "UNM49:275", "code_ISO3166_1_a2": "PS", "code_ISO3166_1_a3": "PSE", "value": "State of Palestine" },
        { "code_UNM49": "UNM49:276", "code_ISO3166_1_a2": "DE", "code_ISO3166_1_a3": "DEU", "value": "Germany" },
        { "code_UNM49": "UNM49:288", "code_ISO3166_1_a2": "GH", "code_ISO3166_1_a3": "GHA", "value": "Ghana" },
        { "code_UNM49": "UNM49:292", "code_ISO3166_1_a2": "GI", "code_ISO3166_1_a3": "GIB", "value": "Gibraltar" },
        { "code_UNM49": "UNM49:296", "code_ISO3166_1_a2": "KI", "code_ISO3166_1_a3": "KIR", "value": "Kiribati" },
        { "code_UNM49": "UNM49:300", "code_ISO3166_1_a2": "GR", "code_ISO3166_1_a3": "GRC", "value": "Greece" },
        { "code_UNM49": "UNM49:304", "code_ISO3166_1_a2": "GL", "code_ISO3166_1_a3": "GRL", "value": "Greenland" },
        { "code_UNM49": "UNM49:308", "code_ISO3166_1_a2": "GD", "code_ISO3166_1_a3": "GRD", "value": "Grenada" },
        { "code_UNM49": "UNM49:312", "code_ISO3166_1_a2": "GP", "code_ISO3166_1_a3": "GLP", "value": "Guadeloupe" },
        { "code_UNM49": "UNM49:316", "code_ISO3166_1_a2": "GU", "code_ISO3166_1_a3": "GUM", "value": "Guam" },
        { "code_UNM49": "UNM49:320", "code_ISO3166_1_a2": "GT", "code_ISO3166_1_a3": "GTM", "value": "Guatemala" },
        { "code_UNM49": "UNM49:324", "code_ISO3166_1_a2": "GN", "code_ISO3166_1_a3": "GIN", "value": "Guinea" },
        { "code_UNM49": "UNM49:328", "code_ISO3166_1_a2": "GY", "code_ISO3166_1_a3": "GUY", "value": "Guyana" },
        { "code_UNM49": "UNM49:332", "code_ISO3166_1_a2": "HT", "code_ISO3166_1_a3": "HTI", "value": "Haiti" },
        { "code_UNM49": "UNM49:334", "code_ISO3166_1_a2": "HM", "code_ISO3166_1_a3": "HMD", "value": "Heard Island and McDonald Islands" },
        { "code_UNM49": "UNM49:336", "code_ISO3166_1_a2": "VA", "code_ISO3166_1_a3": "VAT", "value": "Vatican City State" },
        { "code_UNM49": "UNM49:340", "code_ISO3166_1_a2": "HN", "code_ISO3166_1_a3": "HND", "value": "Honduras" },
        { "code_UNM49": "UNM49:344", "code_ISO3166_1_a2": "HK", "code_ISO3166_1_a3": "HKG", "value": "Hong Kong" },
        { "code_UNM49": "UNM49:348", "code_ISO3166_1_a2": "HU", "code_ISO3166_1_a3": "HUN", "value": "Hungary" },
        { "code_UNM49": "UNM49:352", "code_ISO3166_1_a2": "IS", "code_ISO3166_1_a3": "ISL", "value": "Iceland" },
        { "code_UNM49": "UNM49:356", "code_ISO3166_1_a2": "IN", "code_ISO3166_1_a3": "IND", "value": "India" },
        { "code_UNM49": "UNM49:360", "code_ISO3166_1_a2": "ID", "code_ISO3166_1_a3": "IDN", "value": "Indonesia" },
        { "code_UNM49": "UNM49:364", "code_ISO3166_1_a2": "IR", "code_ISO3166_1_a3": "IRN", "value": "Iran" },
        { "code_UNM49": "UNM49:368", "code_ISO3166_1_a2": "IQ", "code_ISO3166_1_a3": "IRQ", "value": "Iraq" },
        { "code_UNM49": "UNM49:372", "code_ISO3166_1_a2": "IE", "code_ISO3166_1_a3": "IRL", "value": "Ireland" },
        { "code_UNM49": "UNM49:376", "code_ISO3166_1_a2": "IL", "code_ISO3166_1_a3": "ISR", "value": "Israel" },
        { "code_UNM49": "UNM49:380", "code_ISO3166_1_a2": "IT", "code_ISO3166_1_a3": "ITA", "value": "Italy" },
        { "code_UNM49": "UNM49:384", "code_ISO3166_1_a2": "CI", "code_ISO3166_1_a3": "CIV", "value": "Côte d’Ivoire" },
        { "code_UNM49": "UNM49:388", "code_ISO3166_1_a2": "JM", "code_ISO3166_1_a3": "JAM", "value": "Jamaica" },
        { "code_UNM49": "UNM49:392", "code_ISO3166_1_a2": "JP", "code_ISO3166_1_a3": "JPN", "value": "Japan" },
        { "code_UNM49": "UNM49:398", "code_ISO3166_1_a2": "KZ", "code_ISO3166_1_a3": "KAZ", "value": "Kazakhstan" },
        { "code_UNM49": "UNM49:400", "code_ISO3166_1_a2": "JO", "code_ISO3166_1_a3": "JOR", "value": "Jordan" },
        { "code_UNM49": "UNM49:404", "code_ISO3166_1_a2": "KE", "code_ISO3166_1_a3": "KEN", "value": "Kenya" },
        { "code_UNM49": "UNM49:408", "code_ISO3166_1_a2": "KP", "code_ISO3166_1_a3": "PRK", "value": "Democratic People''s Republic of Korea" },
        { "code_UNM49": "UNM49:410", "code_ISO3166_1_a2": "KR", "code_ISO3166_1_a3": "KOR", "value": "Korea (the Republic of)" },
        { "code_UNM49": "UNM49:414", "code_ISO3166_1_a2": "KW", "code_ISO3166_1_a3": "KWT", "value": "Kuwait" },
        { "code_UNM49": "UNM49:417", "code_ISO3166_1_a2": "KG", "code_ISO3166_1_a3": "KGZ", "value": "Kyrgyzstan" },
        { "code_UNM49": "UNM49:418", "code_ISO3166_1_a2": "LA", "code_ISO3166_1_a3": "LAO", "value": "Laos" },
        { "code_UNM49": "UNM49:422", "code_ISO3166_1_a2": "LB", "code_ISO3166_1_a3": "LBN", "value": "Lebanon" },
        { "code_UNM49": "UNM49:426", "code_ISO3166_1_a2": "LS", "code_ISO3166_1_a3": "LSO", "value": "Lesotho" },
        { "code_UNM49": "UNM49:428", "code_ISO3166_1_a2": "LV", "code_ISO3166_1_a3": "LVA", "value": "Latvia" },
        { "code_UNM49": "UNM49:430", "code_ISO3166_1_a2": "LR", "code_ISO3166_1_a3": "LBR", "value": "Liberia" },
        { "code_UNM49": "UNM49:434", "code_ISO3166_1_a2": "LY", "code_ISO3166_1_a3": "LBY", "value": "Libya" },
        { "code_UNM49": "UNM49:438", "code_ISO3166_1_a2": "LI", "code_ISO3166_1_a3": "LIE", "value": "Liechtenstein" },
        { "code_UNM49": "UNM49:440", "code_ISO3166_1_a2": "LT", "code_ISO3166_1_a3": "LTU", "value": "Lithuania" },
        { "code_UNM49": "UNM49:442", "code_ISO3166_1_a2": "LU", "code_ISO3166_1_a3": "LUX", "value": "Luxembourg" },
        { "code_UNM49": "UNM49:446", "code_ISO3166_1_a2": "MO", "code_ISO3166_1_a3": "MAC", "value": "Macao" },
        { "code_UNM49": "UNM49:450", "code_ISO3166_1_a2": "MG", "code_ISO3166_1_a3": "MDG", "value": "Madagascar" },
        { "code_UNM49": "UNM49:454", "code_ISO3166_1_a2": "MW", "code_ISO3166_1_a3": "MWI", "value": "Malawi" },
        { "code_UNM49": "UNM49:458", "code_ISO3166_1_a2": "MY", "code_ISO3166_1_a3": "MYS", "value": "Malaysia" },
        { "code_UNM49": "UNM49:462", "code_ISO3166_1_a2": "MV", "code_ISO3166_1_a3": "MDV", "value": "Maldives" },
        { "code_UNM49": "UNM49:466", "code_ISO3166_1_a2": "ML", "code_ISO3166_1_a3": "MLI", "value": "Mali" },
        { "code_UNM49": "UNM49:470", "code_ISO3166_1_a2": "MT", "code_ISO3166_1_a3": "MLT", "value": "Malta" },
        { "code_UNM49": "UNM49:474", "code_ISO3166_1_a2": "MQ", "code_ISO3166_1_a3": "MTQ", "value": "Martinique" },
        { "code_UNM49": "UNM49:478", "code_ISO3166_1_a2": "MR", "code_ISO3166_1_a3": "MRT", "value": "Mauritania" },
        { "code_UNM49": "UNM49:480", "code_ISO3166_1_a2": "MU", "code_ISO3166_1_a3": "MUS", "value": "Mauritius" },
        { "code_UNM49": "UNM49:484", "code_ISO3166_1_a2": "MX", "code_ISO3166_1_a3": "MEX", "value": "Mexico" },
        { "code_UNM49": "UNM49:492", "code_ISO3166_1_a2": "MC", "code_ISO3166_1_a3": "MCO", "value": "Monaco" },
        { "code_UNM49": "UNM49:496", "code_ISO3166_1_a2": "MN", "code_ISO3166_1_a3": "MNG", "value": "Mongolia" },
        { "code_UNM49": "UNM49:498", "code_ISO3166_1_a2": "MD", "code_ISO3166_1_a3": "MDA", "value": "Moldova" },
        { "code_UNM49": "UNM49:499", "code_ISO3166_1_a2": "ME", "code_ISO3166_1_a3": "MNE", "value": "Montenegro" },
        { "code_UNM49": "UNM49:500", "code_ISO3166_1_a2": "MS", "code_ISO3166_1_a3": "MSR", "value": "Montserrat" },
        { "code_UNM49": "UNM49:504", "code_ISO3166_1_a2": "MA", "code_ISO3166_1_a3": "MAR", "value": "Morocco" },
        { "code_UNM49": "UNM49:508", "code_ISO3166_1_a2": "MZ", "code_ISO3166_1_a3": "MOZ", "value": "Mozambique" },
        { "code_UNM49": "UNM49:512", "code_ISO3166_1_a2": "OM", "code_ISO3166_1_a3": "OMN", "value": "Oman" },
        { "code_UNM49": "UNM49:516", "code_ISO3166_1_a2": "NA", "code_ISO3166_1_a3": "NAM", "value": "Namibia" },
        { "code_UNM49": "UNM49:520", "code_ISO3166_1_a2": "NR", "code_ISO3166_1_a3": "NRU", "value": "Nauru" },
        { "code_UNM49": "UNM49:524", "code_ISO3166_1_a2": "NP", "code_ISO3166_1_a3": "NPL", "value": "Nepal" },
        { "code_UNM49": "UNM49:528", "code_ISO3166_1_a2": "NL", "code_ISO3166_1_a3": "NLD", "value": "Netherlands" },
        { "code_UNM49": "UNM49:531", "code_ISO3166_1_a2": "CW", "code_ISO3166_1_a3": "CUW", "value": "Curaçao" },
        { "code_UNM49": "UNM49:533", "code_ISO3166_1_a2": "AW", "code_ISO3166_1_a3": "ABW", "value": "Aruba" },
        { "code_UNM49": "UNM49:534", "code_ISO3166_1_a2": "SX", "code_ISO3166_1_a3": "SXM", "value": "Sint Maarten (Dutch part)" },
        { "code_UNM49": "UNM49:535", "code_ISO3166_1_a2": "BQ", "code_ISO3166_1_a3": "BES", "value": "Bonaire, Sint Eustatius and Saba" },
        { "code_UNM49": "UNM49:540", "code_ISO3166_1_a2": "NC", "code_ISO3166_1_a3": "NCL", "value": "New Caledonia" },
        { "code_UNM49": "UNM49:548", "code_ISO3166_1_a2": "VU", "code_ISO3166_1_a3": "VUT", "value": "Vanuatu" },
        { "code_UNM49": "UNM49:554", "code_ISO3166_1_a2": "NZ", "code_ISO3166_1_a3": "NZL", "value": "New Zealand" },
        { "code_UNM49": "UNM49:558", "code_ISO3166_1_a2": "NI", "code_ISO3166_1_a3": "NIC", "value": "Nicaragua" },
        { "code_UNM49": "UNM49:562", "code_ISO3166_1_a2": "NE", "code_ISO3166_1_a3": "NER", "value": "Niger" },
        { "code_UNM49": "UNM49:566", "code_ISO3166_1_a2": "NG", "code_ISO3166_1_a3": "NGA", "value": "Nigeria" },
        { "code_UNM49": "UNM49:570", "code_ISO3166_1_a2": "NU", "code_ISO3166_1_a3": "NIU", "value": "Niue" },
        { "code_UNM49": "UNM49:574", "code_ISO3166_1_a2": "NF", "code_ISO3166_1_a3": "NFK", "value": "Norfolk Island" },
        { "code_UNM49": "UNM49:578", "code_ISO3166_1_a2": "NO", "code_ISO3166_1_a3": "NOR", "value": "Norway" },
        { "code_UNM49": "UNM49:580", "code_ISO3166_1_a2": "MP", "code_ISO3166_1_a3": "MNP", "value": "Northern Mariana Islands" },
        { "code_UNM49": "UNM49:581", "code_ISO3166_1_a2": "UM", "code_ISO3166_1_a3": "UMI", "value": "United States Minor Outlying Islands" },
        { "code_UNM49": "UNM49:583", "code_ISO3166_1_a2": "FM", "code_ISO3166_1_a3": "FSM", "value": "Micronesia (Federated States of)" },
        { "code_UNM49": "UNM49:584", "code_ISO3166_1_a2": "MH", "code_ISO3166_1_a3": "MHL", "value": "Marshall Islands" },
        { "code_UNM49": "UNM49:585", "code_ISO3166_1_a2": "PW", "code_ISO3166_1_a3": "PLW", "value": "Palau" },
        { "code_UNM49": "UNM49:586", "code_ISO3166_1_a2": "PK", "code_ISO3166_1_a3": "PAK", "value": "Pakistan" },
        { "code_UNM49": "UNM49:591", "code_ISO3166_1_a2": "PA", "code_ISO3166_1_a3": "PAN", "value": "Panama" },
        { "code_UNM49": "UNM49:598", "code_ISO3166_1_a2": "PG", "code_ISO3166_1_a3": "PNG", "value": "Papua New Guinea" },
        { "code_UNM49": "UNM49:600", "code_ISO3166_1_a2": "PY", "code_ISO3166_1_a3": "PRY", "value": "Paraguay" },
        { "code_UNM49": "UNM49:604", "code_ISO3166_1_a2": "PE", "code_ISO3166_1_a3": "PER", "value": "Peru" },
        { "code_UNM49": "UNM49:608", "code_ISO3166_1_a2": "PH", "code_ISO3166_1_a3": "PHL", "value": "Philippines" },
        { "code_UNM49": "UNM49:612", "code_ISO3166_1_a2": "PN", "code_ISO3166_1_a3": "PCN", "value": "Pitcairn" },
        { "code_UNM49": "UNM49:616", "code_ISO3166_1_a2": "PL", "code_ISO3166_1_a3": "POL", "value": "Poland" },
        { "code_UNM49": "UNM49:620", "code_ISO3166_1_a2": "PT", "code_ISO3166_1_a3": "PRT", "value": "Portugal" },
        { "code_UNM49": "UNM49:624", "code_ISO3166_1_a2": "GW", "code_ISO3166_1_a3": "GNB", "value": "Guinea-Bissau" },
        { "code_UNM49": "UNM49:626", "code_ISO3166_1_a2": "TL", "code_ISO3166_1_a3": "TLS", "value": "Timor-Leste" },
        { "code_UNM49": "UNM49:630", "code_ISO3166_1_a2": "PR", "code_ISO3166_1_a3": "PRI", "value": "Puerto Rico" },
        { "code_UNM49": "UNM49:634", "code_ISO3166_1_a2": "QA", "code_ISO3166_1_a3": "QAT", "value": "Qatar" },
        { "code_UNM49": "UNM49:638", "code_ISO3166_1_a2": "RE", "code_ISO3166_1_a3": "REU", "value": "Réunion" },
        { "code_UNM49": "UNM49:642", "code_ISO3166_1_a2": "RO", "code_ISO3166_1_a3": "ROU", "value": "Romania" },
        { "code_UNM49": "UNM49:643", "code_ISO3166_1_a2": "RU", "code_ISO3166_1_a3": "RUS", "value": "Russia" },
        { "code_UNM49": "UNM49:646", "code_ISO3166_1_a2": "RW", "code_ISO3166_1_a3": "RWA", "value": "Rwanda" },
        { "code_UNM49": "UNM49:652", "code_ISO3166_1_a2": "BL", "code_ISO3166_1_a3": "BLM", "value": "Saint Barthélemy" },
        { "code_UNM49": "UNM49:654", "code_ISO3166_1_a2": "SH", "code_ISO3166_1_a3": "SHN", "value": "Saint Helena" },
        { "code_UNM49": "UNM49:659", "code_ISO3166_1_a2": "KN", "code_ISO3166_1_a3": "KNA", "value": "Saint Kitts and Nevis" },
        { "code_UNM49": "UNM49:660", "code_ISO3166_1_a2": "AI", "code_ISO3166_1_a3": "AIA", "value": "Anguilla" },
        { "code_UNM49": "UNM49:662", "code_ISO3166_1_a2": "LC", "code_ISO3166_1_a3": "LCA", "value": "Saint Lucia" },
        { "code_UNM49": "UNM49:663", "code_ISO3166_1_a2": "MF", "code_ISO3166_1_a3": "MAF", "value": "Saint Martin (French Part)" },
        { "code_UNM49": "UNM49:666", "code_ISO3166_1_a2": "PM", "code_ISO3166_1_a3": "SPM", "value": "Saint Pierre and Miquelon" },
        { "code_UNM49": "UNM49:670", "code_ISO3166_1_a2": "VC", "code_ISO3166_1_a3": "VCT", "value": "Saint Vincent and the Grenadines" },
        { "code_UNM49": "UNM49:674", "code_ISO3166_1_a2": "SM", "code_ISO3166_1_a3": "SMR", "value": "San Marino" },
        { "code_UNM49": "UNM49:678", "code_ISO3166_1_a2": "ST", "code_ISO3166_1_a3": "STP", "value": "Sao Tome and Principe" },
        { "code_UNM49": "UNM49:682", "code_ISO3166_1_a2": "SA", "code_ISO3166_1_a3": "SAU", "value": "Saudi Arabia" },
        { "code_UNM49": "UNM49:686", "code_ISO3166_1_a2": "SN", "code_ISO3166_1_a3": "SEN", "value": "Senegal" },
        { "code_UNM49": "UNM49:688", "code_ISO3166_1_a2": "RS", "code_ISO3166_1_a3": "SRB", "value": "Serbia" },
        { "code_UNM49": "UNM49:690", "code_ISO3166_1_a2": "SC", "code_ISO3166_1_a3": "SYC", "value": "Seychelles" },
        { "code_UNM49": "UNM49:694", "code_ISO3166_1_a2": "SL", "code_ISO3166_1_a3": "SLE", "value": "Sierra Leone" },
        { "code_UNM49": "UNM49:702", "code_ISO3166_1_a2": "SG", "code_ISO3166_1_a3": "SGP", "value": "Singapore" },
        { "code_UNM49": "UNM49:703", "code_ISO3166_1_a2": "SK", "code_ISO3166_1_a3": "SVK", "value": "Slovakia" },
        { "code_UNM49": "UNM49:704", "code_ISO3166_1_a2": "VN", "code_ISO3166_1_a3": "VNM", "value": "Viet Nam" },
        { "code_UNM49": "UNM49:705", "code_ISO3166_1_a2": "SI", "code_ISO3166_1_a3": "SVN", "value": "Slovenia" },
        { "code_UNM49": "UNM49:706", "code_ISO3166_1_a2": "SO", "code_ISO3166_1_a3": "SOM", "value": "Somalia" },
        { "code_UNM49": "UNM49:710", "code_ISO3166_1_a2": "ZA", "code_ISO3166_1_a3": "ZAF", "value": "South Africa" },
        { "code_UNM49": "UNM49:716", "code_ISO3166_1_a2": "ZW", "code_ISO3166_1_a3": "ZWE", "value": "Zimbabwe" },
        { "code_UNM49": "UNM49:724", "code_ISO3166_1_a2": "ES", "code_ISO3166_1_a3": "ESP", "value": "Spain" },
        { "code_UNM49": "UNM49:728", "code_ISO3166_1_a2": "SS", "code_ISO3166_1_a3": "SSD", "value": "South Sudan" },
        { "code_UNM49": "UNM49:729", "code_ISO3166_1_a2": "SD", "code_ISO3166_1_a3": "SDN", "value": "Sudan" },
        { "code_UNM49": "UNM49:732", "code_ISO3166_1_a2": "EH", "code_ISO3166_1_a3": "ESH", "value": "Western Sahara" },
        { "code_UNM49": "UNM49:740", "code_ISO3166_1_a2": "SR", "code_ISO3166_1_a3": "SUR", "value": "Suriname" },
        { "code_UNM49": "UNM49:744", "code_ISO3166_1_a2": "SJ", "code_ISO3166_1_a3": "SJM", "value": "Svalbard and Jan Mayen Islands" },
        { "code_UNM49": "UNM49:748", "code_ISO3166_1_a2": "SZ", "code_ISO3166_1_a3": "SWZ", "value": "Eswatini" },
        { "code_UNM49": "UNM49:752", "code_ISO3166_1_a2": "SE", "code_ISO3166_1_a3": "SWE", "value": "Sweden" },
        { "code_UNM49": "UNM49:756", "code_ISO3166_1_a2": "CH", "code_ISO3166_1_a3": "CHE", "value": "Switzerland" },
        { "code_UNM49": "UNM49:760", "code_ISO3166_1_a2": "SY", "code_ISO3166_1_a3": "SYR", "value": "Syria" },
        { "code_UNM49": "UNM49:762", "code_ISO3166_1_a2": "TJ", "code_ISO3166_1_a3": "TJK", "value": "Tajikistan" },
        { "code_UNM49": "UNM49:764", "code_ISO3166_1_a2": "TH", "code_ISO3166_1_a3": "THA", "value": "Thailand" },
        { "code_UNM49": "UNM49:768", "code_ISO3166_1_a2": "TG", "code_ISO3166_1_a3": "TGO", "value": "Togo" },
        { "code_UNM49": "UNM49:772", "code_ISO3166_1_a2": "TK", "code_ISO3166_1_a3": "TKL", "value": "Tokelau" },
        { "code_UNM49": "UNM49:776", "code_ISO3166_1_a2": "TO", "code_ISO3166_1_a3": "TON", "value": "Tonga" },
        { "code_UNM49": "UNM49:780", "code_ISO3166_1_a2": "TT", "code_ISO3166_1_a3": "TTO", "value": "Trinidad and Tobago" },
        { "code_UNM49": "UNM49:784", "code_ISO3166_1_a2": "AE", "code_ISO3166_1_a3": "ARE", "value": "United Arab Emirates" },
        { "code_UNM49": "UNM49:788", "code_ISO3166_1_a2": "TN", "code_ISO3166_1_a3": "TUN", "value": "Tunisia" },
        { "code_UNM49": "UNM49:792", "code_ISO3166_1_a2": "TR", "code_ISO3166_1_a3": "TUR", "value": "Turkey" },
        { "code_UNM49": "UNM49:795", "code_ISO3166_1_a2": "TM", "code_ISO3166_1_a3": "TKM", "value": "Turkmenistan" },
        { "code_UNM49": "UNM49:796", "code_ISO3166_1_a2": "TC", "code_ISO3166_1_a3": "TCA", "value": "Turks and Caicos Islands" },
        { "code_UNM49": "UNM49:798", "code_ISO3166_1_a2": "TV", "code_ISO3166_1_a3": "TUV", "value": "Tuvalu" },
        { "code_UNM49": "UNM49:800", "code_ISO3166_1_a2": "UG", "code_ISO3166_1_a3": "UGA", "value": "Uganda" },
        { "code_UNM49": "UNM49:804", "code_ISO3166_1_a2": "UA", "code_ISO3166_1_a3": "UKR", "value": "Ukraine" },
        { "code_UNM49": "UNM49:807", "code_ISO3166_1_a2": "MK", "code_ISO3166_1_a3": "MKD", "value": "North Macedonia" },
        { "code_UNM49": "UNM49:818", "code_ISO3166_1_a2": "EG", "code_ISO3166_1_a3": "EGY", "value": "Egypt" },
        { "code_UNM49": "UNM49:826", "code_ISO3166_1_a2": "GB", "code_ISO3166_1_a3": "GBR", "value": "United Kingdom" },
        { "code_UNM49": "UNM49:831", "code_ISO3166_1_a2": "GG", "code_ISO3166_1_a3": "GGY", "value": "Guernsey" },
        { "code_UNM49": "UNM49:832", "code_ISO3166_1_a2": "JE", "code_ISO3166_1_a3": "JEY", "value": "Jersey" },
        { "code_UNM49": "UNM49:833", "code_ISO3166_1_a2": "IM", "code_ISO3166_1_a3": "IMN", "value": "Isle of Man" },
        { "code_UNM49": "UNM49:834", "code_ISO3166_1_a2": "TZ", "code_ISO3166_1_a3": "TZA", "value": "Tanzania" },
        { "code_UNM49": "UNM49:840", "code_ISO3166_1_a2": "US", "code_ISO3166_1_a3": "USA", "value": "United States of America" },
        { "code_UNM49": "UNM49:850", "code_ISO3166_1_a2": "VI", "code_ISO3166_1_a3": "VIR", "value": "United States Virgin Islands" },
        { "code_UNM49": "UNM49:854", "code_ISO3166_1_a2": "BF", "code_ISO3166_1_a3": "BFA", "value": "Burkina Faso" },
        { "code_UNM49": "UNM49:858", "code_ISO3166_1_a2": "UY", "code_ISO3166_1_a3": "URY", "value": "Uruguay" },
        { "code_UNM49": "UNM49:860", "code_ISO3166_1_a2": "UZ", "code_ISO3166_1_a3": "UZB", "value": "Uzbekistan" },
        { "code_UNM49": "UNM49:862", "code_ISO3166_1_a2": "VE ", "code_ISO3166_1_a3": "VEN", "value": "Venezuela" },
        { "code_UNM49": "UNM49:876", "code_ISO3166_1_a2": "WF", "code_ISO3166_1_a3": "WLF", "value": "Wallis and Futuna Islands" },
        { "code_UNM49": "UNM49:882", "code_ISO3166_1_a2": "WS", "code_ISO3166_1_a3": "WSM", "value": "Samoa" },
        { "code_UNM49": "UNM49:887", "code_ISO3166_1_a2": "YE", "code_ISO3166_1_a3": "YEM", "value": "Yemen" },
        { "code_UNM49": "UNM49:894", "code_ISO3166_1_a2": "ZM", "code_ISO3166_1_a3": "ZMB", "value": "Zambia" }
    ];

    useEffect(() => {
        if(props.tableData){
            setTotal(props.total);

            let SearchResults = [...props.tableData.results];

            const mRes = SearchResults.map(item  => {

                let objTitle = item.title[0].value;
                for (let i = 0; i < item.title.length; i++) {
                    if (item.title[i].language === "English") {
                        objTitle = item.title[i].value;
                    }
                }
                //-----------------------------------------
                let objID = "";
                for (let i = 0; i < item.dataNODE_id.length; i++) {
                    if (item.dataNODE_id[i].primary) {
                        objID = item.dataNODE_id[i].hid;
                    }
                }
                //-----------------------------------------
                let exampleT = "";
                if (item.example_title) {
                    exampleT = item.example_title;
                }

                let obj = {
                    release_year: item.release_year,
                    category : item.resource_type.category,
                    title : objTitle,
                    access : item.rights.access,
                    example_title : exampleT,
                    license : item.rights.license,
                    citation : item.citation,
                    dataNODE_id: objID
                };

                // console.log(obj);
                return(obj)
            });
            setMainData(mRes);
            //-----------------------------------
            setUrlPath(props.urlPath)
            //-----------------------------------
            let anresCountries = [...props.tableData.summaries.countries];
            const dataREScountries = anresCountries.map(item  => {
                let country_value = "";
                let country_code_ISO3166_1_a2 = "";
                let country_code_ISO3166_1_a3 = "";
                //-------------------
                for (let i = 0; i < varCOUNTRIES.length; i++) {
                    if (varCOUNTRIES[i].code_UNM49 === item.code_UNM49) {
                        country_value = varCOUNTRIES[i].value;
                        country_code_ISO3166_1_a2 = varCOUNTRIES[i].code_ISO3166_1_a2;
                        country_code_ISO3166_1_a3 = varCOUNTRIES[i].code_ISO3166_1_a3;
                    }
                }
                //-------------------
                let obj = {
                    value: country_value,
                    code_ISO3166_1_a2 : country_code_ISO3166_1_a2,
                    code_ISO3166_1_a3 : country_code_ISO3166_1_a3,
                    code_UNM49 : item.code_UNM49,
                    frequency : item.doc_count
                };
                return(obj)
            });
            //-----------------
            let anresAgrovoc = [...props.tableData.summaries.keywords];
            const dataRESagrovoc = anresAgrovoc.map(item  => {
                if ( item.scheme !== "free") {
                    let obj = {
                        value: item.value,
                        frequency : item.doc_count
                    };
                    return(obj)
                }
            });
            //-----------------
            // let anresKeyword = [...props.tableData.summaries.keywords];
            // const dataRESkeyword = anresKeyword.map(item  => {
            //     if ( item.scheme == "free") {
            //         let obj = {
            //             value: item.value,
            //             frequency : item.doc_count
            //         };
            //         return(obj)
            //     }
            // });
            //-----------------
            let anresProviders = [...props.tableData.summaries.providers];
            const dataRESproviders = anresProviders.map(item  => {

                let provider_short_name = "";
                for (let i = 0; i < varPROVIDERS.length; i++) {
                    if (varPROVIDERS[i].label === item.full_name) {
                        provider_short_name = varPROVIDERS[i].name;
                    }
                }

                let obj = {
                    name: item.full_name,
                    value: provider_short_name,
                    frequency : item.doc_count
                };
                return(obj)
            });
            //-----------------
            let anresProjects = [...props.tableData.summaries.projects];
            const dataRESprojects = anresProjects.map(item  => {

                let project_short_name = "";
                for (let i = 0; i < varPROJECTS.length; i++) {
                    if (varPROJECTS[i].label === item.full_name) {
                        project_short_name = varPROJECTS[i].name;
                    }
                }

                let obj = {
                    name: item.full_name,
                    value: project_short_name,
                    frequency : item.doc_count
                };
                return(obj)
            });
            //-----------------
            let anresFunders = [...props.tableData.summaries.funders];
            const dataRESfunders = anresFunders.map(item  => {
                let obj = {
                    value: item.short_name,
                    name: item.full_name,
                    voc_code: item.full_name,
                    url: item.value,
                    frequency : item.doc_count
                };
                return(obj)
            });
            //-----------------
            let anresAuthors = [...props.tableData.summaries.authors];
            const dataRESauthors = anresAuthors.map(item  => {
                let obj = {
                    value: item.full_name,
                    voc_code: item.url,
                    frequency : item.doc_count
                };
                return(obj)
            });


            //-----------------


            let analyticRes = {
                "summaries": {
                    countries: dataREScountries,
                    agrovoc: dataRESagrovoc,
                    // keywords: dataRESkeyword,
                    providers: dataRESproviders,
                    projects: dataRESprojects,
                    funders: dataRESfunders,
                    authors: dataRESauthors
                }
            };

            setResultsDATA(analyticRes);
            //-----------------------------------
            setFullQUERY(props.fullQUERY);

        }
    }, [props.tableData]);


    useEffect(() => {
        if(props.fullData){

            let SearchResults = [...props.fullData.results];
            //-----------------------------------
            const expRes = SearchResults.map(item  => {

                let objTitle = item.title[0].value;
                for (let i = 0; i < item.title.length; i++) {
                    if (item.title[i].language === "English") {
                        objTitle = item.title[i].value;
                    }
                }
                //-----------------------------------------
                let objID = "";
                for (let i = 0; i < item.dataNODE_id.length; i++) {
                    if (item.dataNODE_id[i].primary) {
                        objID = item.dataNODE_id[i].hid;
                    }
                }
                //-----------------------------------------
                let obj = {
                    ReleaseYear: item.release_year,
                    Type : item.resource_type.category,
                    Title : objTitle,
                    AccessRights : item.rights.access,
                    License : item.rights.license,
                    Citation : item.citation,
                    GARDIANid: objID,
                    GARDIANurl: 'https://gardian.bigdata.cgiar.org/#/asset/'+ objID
                };

                //console.log(obj);

                return(obj)

            });
            setExportResults(expRes);

        }
    }, [props.fullData]);



    let ResourceTypes = [];


    if (props.AssetType === "datasets") {

        ResourceTypes = [
            {category: "Dataset"},
            {category: "Dataset / Geospatial"},
            {category: "Dataset / Omics"},
            {category: "Dataset / Tabular"},
            {category: "Other"}
        ];

    } else {

        ResourceTypes = [
            {category: "Bibliography"},
            {category: "Book / Monograph"},
            {category: "Brief"},
            {category: "Factsheet"},
            {category: "Internal Document"},
            {category: "Magazine or Press item"},
            {category: "Map"},
            {category: "Multimedia"},
            {category: "Online Sources"},
            {category: "Periodical / Journal"},
            {category: "Periodical / Magazine"},
            {category: "Policy Document"},
            {category: "Poster / Presentation"},
            {category: "Report"},
            {category: "Report / Annual"},
            {category: "Report / Case study"},
            {category: "Report / Donor"},
            {category: "Report / Evaluation"},
            {category: "Report / Financial"},
            {category: "Report / Plan"},
            {category: "Report / Strategy"},
            {category: "Report / Technical"},
            {category: "Research Instrument"},
            {category: "Scientific Publication"},
            {category: "Software"},
            {category: "Thesis"},
            {category: "Training Material"},
            {category: "Working Paper"},
            {category: "Other"}
        ];

    }

    const access_type = [ "Open", "Limited" ];

    const cols = [
        { field: 'release_year', header: 'Year' },
        { field: 'title', header: 'Title' },
        { field: 'category', header: 'Type' },
        { field: 'access', header: 'Access Rights' }
    ];

    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

    const onResourceTypeChange = e => {
        dt.current.filter(e.value, "category" , 'in');
        setSelectedResourceType(e.value);
    }

    const onTypesChange = e => {
        dt.current.filter(e.value, 'access', 'equals');
        setSelectedType(e.value);
    }

    const reset = () => {
        setSelectedResourceType(null);
        setSelectedType(null);
        setTitleValue('');
        setYearValue('');
        dt.current.reset();
        props.lazyFunction(props.AssetType,"clear","clear");


    }


    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_Search_Results_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(exportResults);
            const workbook = { Sheets: { 'Search Results': worksheet }, SheetNames: ['Search Results'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'GARDIAN');
        });
    }


    const titleBodyTemplate = data => {
        return <div>
            <div style={{fontSize:"15px",paddingBottom:"10px"}}>{data.example_title}</div>
            <div style={{fontSize:"15px"}}><a href={urlPath + data.dataNODE_id} target="_blank">{data.title}</a></div>
        </div>;
    }


    const imageBodyTemplate = data => {
        if(data.access === "Open"){
            return <>
                <Tooltip target=".image-table"/>
                <img src={'assets/layout/images/open.png'} data-pr-tooltip={data.license} className="image-table" alt=""/>
            </>;
        } else {
          return <>
              <Tooltip target=".image-table"/>
              <img src={'assets/layout/images/close.png'} data-pr-tooltip={data.license} className="image-table" alt=""/>
          </>;
        }
    }

    const header = <div className="p-grid">
        <div className="p-col">
            <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />
        </div>
        <div className="p-col p-d-flex p-jc-center">

            { ((props.total)&&(props.total!=="demo"))?<ResultsAnalytics  resultsDATA={resultsDATA} fullQUERY={fullQUERY} />:console.log() }

        </div>
        <div className="p-col p-d-flex p-jc-end">

            { ((props.fulltotal)&&(props.total>1000)&&(props.total!=="demo"))?
                <Button label="Export Search Results (max 1000)" icon="fad fa-file-download fa-lg" className="exportButton" style={{marginRight: "8px"}} onClick={(event) => exportExcel()} aria-controls="popup_menu" aria-haspopup />
                :

                ((props.fulltotal)&&(props.total!=="demo"))?
                    <Button label="Export Search Results" icon="fad fa-file-download fa-lg" className="exportButton" style={{marginRight: "8px"}} onClick={(event) => exportExcel()} aria-controls="popup_menu" aria-haspopup />
                    :
                    console.log()
            }

        </div>
    </div>

    //Lazy Loading
    const onPage = (event) => {
        props.lazyFunction(props.AssetType,event,"page");
    }

    const onSort = (event) => {
        props.lazyFunction(props.AssetType,event,"sort");
    }

    const onFilter = (event) => {
        props.lazyFunction(props.AssetType,event,"filter");
    }

    const textFilter = (event,filter) => {
        if (event.key === 'Enter') {
            if(filter === 'release_year'){
                let value = {
                        'value':event.target.value,
                        'matchMode': 'contains'
                    }
                props.lazyFunction(props.AssetType,value,filter);
            }else if(filter === 'title'){
                let value = {
                    'value':event.target.value,
                    'matchMode': 'contains'
                }
                props.lazyFunction(props.AssetType,value,filter);
            }
        }
    }

    const ResourceTypeFilter = <MultiSelect value={selectedResourceType} options={ResourceTypes} onChange={onResourceTypeChange} optionLabel="category" optionValue="category" placeholder="All" className="p-column-filter" />;
    const typesFilter = <Dropdown value={selectedType} options={access_type} onChange={onTypesChange}  placeholder="All" className="p-column-filter" showClear />;

    const inputYear = <InputText value={yearValue} onChange={(e)=>setYearValue(e.target.value)} onKeyDown={(event)=>textFilter(event,'release_year')} placeholder="Search" className="p-column-filter" />;
    const inputTitle = <InputText value={titleValue} onChange={(e)=>setTitleValue(e.target.value)} onKeyDown={(event)=>textFilter(event,'title')} placeholder="Search by Title" className="p-column-filter" />;


    return <>
        <DataTable
            ref={dt} header={header}
            paginator loading={props.loading}
            filterDisplay="row"
            lazy={true}
            onPage={onPage}
            onSort={onSort}
            onFilter={onFilter}
            totalRecords={props.total}
            sortField={props.lazyParams.sort_field} sortOrder={props.lazyParams.sort_order}
            filters={props.lazyParams.filters}
            first={props.lazyParams.first}
            value={mainData} rows={props.lazyParams.rows} rowsPerPageOptions={[10,20,50]}>
            <Column field="release_year" filterFunction={textFilter} header="Year" filterElement={inputYear} sortable filter filterPlaceholder="Search" style={{width: "120px"}}/>
            <Column field="title" filterFunction={textFilter} header="Title" body={titleBodyTemplate} filterElement={inputTitle} sortable filter filterPlaceholder="Search by title" />
            <Column field="category" header="Type" sortable filter filterElement={ResourceTypeFilter}  style={{width: "200px"}} />
            <Column field="access" header="Access Rights" body={imageBodyTemplate} sortable filter filterElement={typesFilter}  style={{width: "150px", textAlign: "center"}} />
        </DataTable>

    </>;
}
